import { DatapointResponse } from '../../device/store/device.types';
import { UrlBuilderOption } from '../../workflow/anomaly-configurator/components/url-builder-form-components/types/urlBuilder.types';
import { ALERT_LEVEL } from './alarms-list';
export type ALARM_TYPE = 'ASSET' | 'SENSOR';
export type AlertLevel = keyof typeof ALERT_LEVEL;
export type AlarmStatusType = 'REVIEW' | 'NEW' | 'ACK' | 'IN_PROGRESS' | 'FIXED' | 'FALSE';
export type AlarmSeverityType = 'ALERT_ERROR' | 'ALERT_WARNING' | 'ALERT_LOW' | 'ALERT_FATAL';

export enum ALARM_TAG {
  ALARMS = 'ALARMS',
  ALARM_NOTES = 'ALARM_NOTES',
  CREATE_ALARM_NOTE = 'CREATE_ALARM_NOTE',
  ALARM_TIMELINE = 'ALARM_TIMELINE'
}
/**
 * This is the raw response on an Alarm object from an API.
 * @example Use this interface whenever you don't need the customized response.
 */
export interface AlarmRawObj {
  name: string;
  alarm_id: number;
  alarm_category: string;
  created: number;
  alarm_type: ALARM_TYPE;
  severity: AlertLevel;
  anomaly: string;
  diagnosis: string;
  recommendation: string;
  analyst_diagnosis: string | null;
  analyst_notes: string | null;
  analyst_recommendation: string | null;
  occurrence_count: number;
  closed_reason?: string;
  trigger_value: number;
  latest: number;
  canViewAnalysis: boolean;
  status: AlarmStatusType;
  unit: string;
  org_uuid: string;
  suborg_uuid: string;
  threshold_value: number | null;
  state_modified_by: string | null;
  state_modified_by_user_name: string;
  state_modified_on: number;
  device_ids: number[];
  event_ids: (number | null)[];
  pathName: string | undefined;
  pathDeviceId: string;
  path: string;
  redirect_url: string | null;
  redirect_url_type: UrlBuilderOption | null;
  mtta: number;
  mttr: number;
}

/**
 * This is the customized response on an Alarm object from an API
 * to add more usefull properties for Alams list table.
 */
export interface Alarm extends AlarmRawObj {
  createdColumnData: {
    cellText: string;
    tooltipValue: string;
  };
  latestColumnData: {
    cellText: string;
    tooltipValue?: string;
  };
  stateColumnData: {
    cellText: string;
    tooltipValue: string;
    lastModifiedData: string;
    modifiedByUserName: string;
  };
  siteColumnData: {
    cellText: string;
  };
  pathColumnData: {
    cellText: string;
  };
}
export type FetchAlarmDetailsArgs = {
  slug: string;
  alarmType: ALARM_TYPE;
  isHistoricalAlarms: boolean;
  severity: string[] | undefined;
  /**
   * The start date/time for filtering alarms.
   * This key is not part of the local table filters.
   * When the `start` key appears in the URL, it should
   * be included as a query parameter in the alarm API.
   */
  start: string | undefined;
  /**
   * The end date/time for filtering alarms.
   * This key is not part of the local table filters.
   * When the `end` key appears in the URL, it should be included as a query parameter in the alarm API.
   */
  end: string | undefined;
  showProgressBar?: boolean;
};

export type FetchEventsFromAlarmArgs = {
  slug: string;
  device_id?: number;
  dpids?: string | number;
  datapoints: DatapointResponse[];
  start: number; // The start date/time for filtering alarms.
  end: number; // The end date/time for filtering alarms.
};
export type AlarmEvent = {
  event_id: number;
  device_id: number;
  dpids: Array<number>;
  ts: number;
};
export type FetchAlarmEventsResponse = {
  events: Array<AlarmEvent>;
};
export type FetchAlarmsArgs = {
  slug: string;
  alarmType: ALARM_TYPE;
  isHistoricalAlarms: boolean;
  severity?: string[] | undefined;
  /**
   * The start date/time for filtering alarms.
   * This key is not part of the local table filters.
   * When the `start` key appears in the URL, it should
   * be included as a query parameter in the alarm API.
   */
  start?: string | undefined;
  /**
   * The end date/time for filtering alarms.
   * This key is not part of the local table filters.
   * When the `end` key appears in the URL, it should be included as a query parameter in the alarm API.
   */
  end?: string | undefined;
  device_ids?: string;
  showProgressBar?: boolean;
  alarm_ids?: string;
};

export type FetchAlarmsCountArgs = {
  slug: string;
  count: boolean;
  alarmType: any;
  showProgressBar?: boolean;
};

export interface FetchExportedAlarmsArgs {
  slug: string;
  start: number;
  end: number;
  setting: AlarmExportType;
  email?: string;
  timezone: string;
  alarmType: ALARM_TYPE;
}

export type AlarmExportType = 'download' | 'email' | '';
export type Occurred = string;
export type ModifiedBy = string;
export type Site = string;
export type Path = number;

export type AlarmsFiltersState = {
  /**
   * Filters that are selected in the reducer but not finalized yet.
   */
  selections: {
    severity: AlertLevel[];
    state: AlarmStatusType[];
    modifiedBy: ModifiedBy[];
    site: Site[];
    path: Path[];
    occurred: Occurred;
    name: string;
    id: string;
  };
  /**
   * Finalized filters that are set w.r.t. URL params by user in the reducer.
   */
  setFilters: {
    severity: AlertLevel[];
    state: AlarmStatusType[];
    modifiedBy: ModifiedBy[];
    site: Site[];
    path: Path[];
    occurred: Occurred;
    name: string;
    id: string;
  };
  export: {
    /**
     * Determines whether the exported alarms file should be downloaded
     * for the current user or not.
     * This is used in case the user opens two same tabs in the browser,
     * and one's download action shouldn't interfere with the other tab instance.
     */
    shouldDownloadFile: boolean;
  };
};

export interface AlarmDeleteWebsocketPayload {
  module: string;
  action: string;
  payload: {
    alarms: AlarmRawObj[];
  };
}

export interface UpdateAlarmsStatusArgs {
  slug: string;
  alarmStatus: AlarmStatusType;
  ruleTriggerIds: string[];
  isFixedFalse?: boolean;
  summary?: string;
  analystNotes?: string;
  analystDiagnosis?: string;
  analystRecommendation?: string;
}

export enum ALARMS_VALIDATION_TAGS {
  ALARM_COUNT = 'ALARM_COUNT'
}

//Alarm Notes Types
export enum NoteType {
  Text = 'text',
  Image = 'image',
  PDF = 'application/pdf'
}
export enum Direction {
  Incoming = 'incoming',
  Outgoing = 'outgoing'
}

export interface CreateAlarmNotePayload {
  user_id: string;
  note_type: NoteType | string;
  content: string;
  file?: File;
}
export interface CreateAlarmNoteArgs {
  slug: string;
  alarm_id: number;
  payload: CreateAlarmNotePayload;
}

export interface AlarmNote extends CreateAlarmNotePayload {
  id: number;
  timestamp: string | number;
  user_name?: string;
  direction: Direction;
  get_object_url?: string;
  get_object_url_viewable?: string;
  alarm_note_or_timeline?: AlarmNoteOrTimelineType;
}

export type AlarmNotes = AlarmNote[];
export type FetchAlarmNotesArgs = {
  slug: string;
  alarm_id: number;
};

export interface CreateAlarmNoteResponse {
  success: boolean;
  message: string;
  put_object_url?: string;
}

// Alarm Timelines types
export interface AlarmStateTimeline {
  name: string;
  previous_state: AlarmStatusType | null;
  current_state: AlarmStatusType | null;
  timestamp: number;
  alarm_note_or_timeline: AlarmNoteOrTimelineType;
}
export interface AlarmSeverityTimeline {
  previous_level: AlarmSeverityType | null;
  current_level: AlarmSeverityType | null;
  timestamp: number;
  alarm_note_or_timeline: AlarmNoteOrTimelineType;
}
export type AlarmStateTimelines = AlarmStateTimeline[];
export type AlarmSeverityTimelines = AlarmSeverityTimeline[];

export interface AlarmTimelines {
  state: AlarmStateTimelines;
  severity: AlarmSeverityTimelines;
}

export type FetchAlarmTimelineArgs = {
  slug: string;
  alarm_id: number;
};
export enum AlarmNoteOrTimelineTypeEnum {
  NOTE = 'note',
  STATE_TIMELINE = 'state_timeline',
  SEVERITY_TIMILINE = 'severity_timeline'
}
export type AlarmNoteOrTimelineType =
  | AlarmNoteOrTimelineTypeEnum.NOTE
  | AlarmNoteOrTimelineTypeEnum.STATE_TIMELINE
  | AlarmNoteOrTimelineTypeEnum.SEVERITY_TIMILINE;
