/**
 * Button Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { forwardRef } from 'react';
import { ButtonProps, Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import TranslateText from '../../../i18n/TranslateText';

/**
 * A custom button component built using MUI's `Button` component.
 * @returns {ReactElement} - The SLButton component
 */

interface SLButtonProps extends ButtonProps {
  dataTestId?: string;
  //Id for translation
  translationId?: string;
  defaultMessage?: string;
}

const SLButton = forwardRef<HTMLButtonElement, SLButtonProps>(
  (
    {
      variant = 'contained',
      color = 'primary',
      size = 'small',
      children,
      dataTestId,
      disabled,
      translationId,
      defaultMessage,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const buttonStyle =
      variant === 'contained'
        ? {
            backgroundColor: disabled ? theme.palette.action.disabled : theme.palette.primary.main,
            color: theme.palette.text.secondary
          }
        : {};

    return (
      <Button
        id={dataTestId || translationId}
        variant={variant}
        style={{ ...props.style, ...buttonStyle }}
        color={color}
        size={size}
        ref={ref}
        disabled={disabled}
        {...props}
        type="button">
        {translationId ? TranslateText(translationId, defaultMessage) : children}
      </Button>
    );
  }
);

export default SLButton;
