/**
 *
 */
export enum VA_DEVICE_STATUS {
  INIT,
  FETCHING,
  READY,
  ERROR
}
/**
 *
 */
type OtherDpid = {
  dpid: number;
  odr: number;
  sensorName: string;
};

type DpidGroup = {
  dpid: number;
  otherDpids: OtherDpid[];
};

export interface SelectedDevice {
  device_id: number;
  device_name: string;
  dpids: DpidGroup[];
  sensorDisplayTitle: string;
  otherDpids: DpidGroup[];
}

/**
 *
 */
export interface VADeviceState {
  status: VA_DEVICE_STATUS;
  datapointsList: Array<any>;
  devicesList: Array<any>;
  selectedDatapoints: Array<any>;
  selectedDevices: SelectedDevice[];
  devicesWithDatapoints: Array<any>;
}
