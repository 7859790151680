/**
 * Helper function to reset the chart's common & data-specific settings.
 * @author aditya.bhadange@shorelineiot.com
 */

import { MULTIPLIER } from '../utils/downSamplingData.utils';

/**
 *
 * @param {string} chartType Type of chart.
 * @param {any} newFormValues Current form object from 'getValues' function from React Hook Form.
 * @param setValue Function to manually set the form values from React Hook Form.
 */
export const resetChartEditorForm = (
  chartType: string | undefined,
  newFormValues: any,
  setValue: (key: string, value: any) => void
) => {
  const isDataSettingsPresent = Boolean(
    newFormValues?.dataSettings && Object.entries(newFormValues?.dataSettings)?.length
  );

  if (chartType === 'donut' || chartType === 'pie') {
    // Reset Common Settings for donut or pie chart.
    if (chartType === 'donut') {
      setValue('innerSize', '50');
    } else {
      setValue('innerSize', '');
    }
    setValue('title', '');
    setValue('legands', true);
    setValue('enable3d', false);
    setValue('dataLabelType', 'PERCENTAGE');

    // Reset Data-Specific settings for donut or pie chart.
    if (isDataSettingsPresent) {
      Object.entries(newFormValues?.dataSettings)?.forEach(([key]: [any, any]) => {
        setValue(`dataSettings.${key}.color`, '');
        setValue(`dataSettings.${key}.label`, '');
        setValue(`dataSettings.${key}.params`, '');
        setValue(`dataSettings.${key}.redirection`, '');
      });
    }
  } else if (chartType === 'table') {
    // Reset Common settings for table chart.
    setValue('sorting', false);
    setValue('columnAlignment', 'left');
    setValue('fullRowRedirection', false);
    setValue('fullRowRedirectionType', '');

    // Reset Data-Specific settings for table charts.
    if (isDataSettingsPresent) {
      Object.entries(newFormValues?.dataSettings)?.forEach(([key]: [any, any]) => {
        const conditions = newFormValues?.dataSettings?.[key]?.conditions;
        setValue(`dataSettings.${key}.title`, '');
        setValue(`dataSettings.${key}.hideColumn`, false);
        setValue(`dataSettings.${key}.params`, '');
        setValue(`dataSettings.${key}.redirection`, '');
        setValue(`dataSettings.${key}.dashboard_id`, '');
        if (conditions && conditions?.length) {
          // Resetting conditions array dynamically.
          conditions?.forEach((condition: any, conditionIndex: number) => {
            const formatter =
              newFormValues?.dataSettings?.[key]?.conditions?.[conditionIndex]?.formatter;
            setValue(`dataSettings.${key}.conditions.${conditionIndex}.operator`, '');
            setValue(`dataSettings.${key}.conditions.${conditionIndex}.value`, '');
            if (formatter && formatter?.length) {
              // Resetting formatter array dynamically.
              formatter?.forEach((item: any, formatterIndex: number) => {
                setValue(
                  `dataSettings.${key}.conditions.${conditionIndex}.formatter.${formatterIndex}`,
                  {
                    type: '',
                    format: '',
                    suffix: '',
                    prefix: '',
                    text: '',
                    icon: '',
                    color: '',
                    bgcolor: ''
                  }
                );
              });
            }
          });
        }
      });
    }
  } else if (
    chartType === 'cartesian' ||
    chartType === 'line' ||
    chartType === 'area' ||
    chartType === 'bar' ||
    chartType === 'column' ||
    chartType === 'scatter'
  ) {
    // Reset Common settings for cartesian type charts.
    setValue('crosshair.color', '');
    setValue('crosshair.enable', false);
    setValue('grid.disable', false);
    setValue('grid.width', '');
    setValue('options3d.enabled', false);
    setValue('options3d.alpha', 15);
    setValue('options3d.beta', 15);
    setValue('options3d.depth', 50);
    setValue('singleYAxis', false);
    setValue('singleYAxisTitle', '');
    setValue('xAxisTitle', '');
    setValue('marker', false);
    setValue('manualScaleYAxis', false);
    setValue('yAxisMin', '');
    setValue('yAxisMax', '');
    setValue('threshold', MULTIPLIER);
    setValue('plotLines', []);

    // Reset Data-Specific settings for cartesian type charts.
    if (isDataSettingsPresent) {
      Object.entries(newFormValues?.dataSettings)?.forEach(([key]: [any, any]) => {
        setValue(`dataSettings.${key}.color`, '');
        setValue(`dataSettings.${key}.name`, '');
        setValue(`dataSettings.${key}.params`, '');
        setValue(`dataSettings.${key}.redirection`, '');
        setValue(`dataSettings.${key}.type`, '');
        setValue(`dataSettings.${key}.yAxis.hide`, false);
        setValue(`dataSettings.${key}.yAxis.max`, '');
        setValue(`dataSettings.${key}.yAxis.min`, '');
        setValue(`dataSettings.${key}.yAxis.opposit`, false);
        setValue(`dataSettings.${key}.yAxis.tickInterval`, '');
        setValue(`dataSettings.${key}.yAxis.title`, '');
      });
    }
  }
  // Add Reset Default logic below for any new chart types below as per requirement.
};
