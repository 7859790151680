import { invalidateTagsListId } from './RTK.constants';
import { EndpointConfig } from './axiosBaseQuery';

type ResultType<T> = T extends Array<infer U> ? U : T;
type IdType<T> = T extends { id: infer U } ? U : string;

/**
 * Type guard to check if an object has the specified key.
 *
 * @param obj - The object to check.
 * @param key - The property key to check.
 * @returns True if the key exists in the object.
 */
function hasKey<O extends object, K extends PropertyKey>(
  obj: O,
  key: K
): obj is O & Record<K, unknown> {
  return key in obj;
}

/**
 * Generates cache tags for RTK Query based on API response.
 *
 * @param result - The API result (can be an array or an object).
 * @param type - The RTK Query tag type.
 * @param idAttribute - The unique identifier key (defaults to 'id').
 * @returns An array of tags for RTK Query caching and invalidation.
 */
export const generateTags = <T, K extends keyof ResultType<T>>(
  result: T | undefined,
  type: string,
  idAttribute: K = 'id' as K
): { type: string; id: IdType<ResultType<T>> | string }[] => {
  // No result: only return the invalidation tag.
  if (!result) {
    return [{ type, id: invalidateTagsListId }];
  }

  // Result is an array: map each item and add the invalidation tag.
  if (Array.isArray(result)) {
    return [
      ...result
        .filter((item) => item[idAttribute] !== undefined)
        .map((item) => ({ type, id: item[idAttribute] as IdType<ResultType<T>> })),
      { type, id: invalidateTagsListId }
    ];
  }

  // Result is an object: check that the idAttribute exists and return its tag plus the invalidation tag.
  if (
    typeof result === 'object' &&
    result !== null &&
    hasKey(result, idAttribute) &&
    result[idAttribute] !== undefined
  ) {
    return [
      { type, id: result[idAttribute] as IdType<ResultType<T>> },
      { type, id: invalidateTagsListId }
    ];
  }

  // Fallback: return only the invalidation tag.
  return [{ type, id: invalidateTagsListId }];
};

/**
 *
 * @param options - The options object
 * @returns The options object
 */
export function buildQuery(options: EndpointConfig) {
  return {
    ...options
  };
}
