/**
 * Submit Button
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TranslateText from '../../../i18n/TranslateText';
/**
 *
 */
interface Props extends ButtonProps {
  disablehoverEffect?: boolean;
  textColorOnHover?: string;
  dataTestId: string;
  //Id for translation
  translationId?: string;
}
/**
 *
 * @param param0
 */
export default function SLSubmit(props: Props): ReactElement {
  const {
    children,
    color = 'primary',
    variant = 'contained',
    size = 'small',
    type = 'submit',
    disablehoverEffect = false,
    textColorOnHover = '',
    className,
    dataTestId,
    translationId,
    ...otherProps
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    button: {
      backgroundColor: theme.palette.primary.main
    },
    onHover: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        ...(textColorOnHover && { color: textColorOnHover })
      }
    }
  }));

  const classes = useStyles();

  return (
    <Button
      id={translationId || dataTestId}
      className={`${className} ${
        !disablehoverEffect ? `${classes.onHover} ${classes.button}` : classes.button
      }`}
      type={type}
      color={color}
      variant={variant}
      size={size}
      {...otherProps}>
      {translationId ? TranslateText(translationId) : children}
    </Button>
  );
}
