import { DataPoint } from '../../../../device/store/device.types';
import { EventType, EventsDataObjType, eventsDataType } from '../../store';
import { FREQUENCY_UNIT, Y_AXIS_UNIT, eventsData } from '../../store/waterfall-rtk/waterfall.types';
import { filterAndDiffArray } from '../../store/waterfall-rtk/helper/filterAndDiffArray';
import { trimDecimals } from '../../../../../framework';

/**
 * Extract the values of the `deviceId`, `datapointId`, `start`, and `end`from query parameters
 * @param location
 * @returns
 */
export function parseWaterfallParams(location: any) {
  const waterfallParams = new URLSearchParams(location.search);
  const deviceId = Number(waterfallParams.get('deviceId'));
  const datapointId = Number(waterfallParams.get('datapointId'));
  const start = Number(waterfallParams.get('start')) || Number(waterfallParams.get('startts'));
  const end = Number(waterfallParams.get('end')) || Number(waterfallParams.get('endts'));
  // To make xUnit value backward compatible extracting 'unit' value also
  const xUnit =
    waterfallParams.get('xUnit') ||
    waterfallParams.get('unit') ||
    waterfallParams.get('frequencyUnit');
  const yUnit = waterfallParams.get('motionUnit') || undefined;

  return { deviceId, datapointId, start, end, xUnit, yUnit };
}

export const calculateUnitData = (eventsData: eventsData[], unit: string | null) => {
  if (FREQUENCY_UNIT.Orders === unit) {
    return eventsData
      .filter((event) => typeof event?.speed === 'number' && event.speed !== null)
      .map((event) => {
        const unitDivider = event.speed || 1;
        return {
          ...event,
          data: event.data.map((innerArr) => [
            Number(innerArr[0]) / unitDivider,
            Number(innerArr[1])
          ])
        };
      });
  }

  const unitMultiplier = unit === FREQUENCY_UNIT.CPM ? 60 : 1;
  return eventsData.map((event: eventsData) => ({
    ...event,
    data: event.data.map((innerArr: [number, number]) => [
      Number(innerArr[0]) * unitMultiplier,
      Number(innerArr[1])
    ])
  }));
};

export const DEFAULT_CONFIG_3D = {
  alpha: 10.2,
  beta: 0,
  depth: 1000,
  viewDistance: 10,
  lineGap: 50
};

export const WATERFALL_LOCALSTORAGE_3D_CONFIG = 'waterfall3dConfig';

/**
 * Function return response of multiple eventsdata API call
 * @param fetchDeviceEventsData function to call events data API
 * @param selectedEvents Array of selected event
 * @param slug slug of org
 * @param deviceId  selected device id
 * @param dpid selected datapoints id
 * @param eventsList List of event for given device and datapoints id
 * @param setEventsData setState to set eventsData
 * @param eventsData EventsData for given events
 * @param oldDpid previously selected Dpid
 * @returns combine result of all eventsData API
 */
export const FetchEventsData = async (
  fetchDeviceEventsData: any,
  selectedEvents: string[],
  slug: string,
  deviceId: number | undefined,
  dpid: number,
  eventsList: EventType[] | undefined,
  setEventsData: React.Dispatch<React.SetStateAction<eventsDataType[]>>,
  eventsData: eventsDataType[],
  oldDpid: number | null,
  yUnit?: string,
  yUnitOld?: Y_AXIS_UNIT,
  saveWaterfallEventsSpeed?: Function
) => {
  const [updatedEventsData, newEvents] =
    // Refetch all events data when dpid or yUnit changes
    dpid !== oldDpid || yUnit !== yUnitOld
      ? [[], selectedEvents]
      : filterAndDiffArray(eventsData, selectedEvents);

  const promises = newEvents.map((eventId: string) =>
    fetchDeviceEventsData({
      slug,
      deviceId,
      eventId,
      dpid,
      unit: yUnit || Y_AXIS_UNIT.INCHES_PER_SEC
    })
  );

  const responses = await Promise.all(promises);

  const dataResponses = responses.map((response) => {
    if (response.isLoading) {
      // Optional: Show loading state
      return null;
    }

    if (response.data) {
      return response.data;
    }

    // Optional: Handle error
    return null;
  });

  // This is an array of event Data only for newly selected events
  const eventDataArray = newEvents.map((eventId: number, index: number) => {
    return {
      eventId: eventId,
      data: dataResponses[index]?.event_data?.[dpid]?.data || [],
      speed: dataResponses[index]?.event_data?.[dpid]?.speed_hz
    };
  });
  const eventsListWithTimestamp = eventDataArray.map((event: EventsDataObjType) => {
    const eventDesc = eventsList?.find(
      (eventObject: EventType) => eventObject.event_id === event.eventId
    );
    //We're adding the epochTime value to sort data because the order of eventId may not always be ascending
    const timeStamp = eventDesc
      ? new Date(`${eventDesc.event_date} ${eventDesc.event_time}`)
      : null;
    return {
      ...event,
      ts: eventDesc ? `${eventDesc.event_date} ${eventDesc.event_time}` : null,
      epochTime: timeStamp ? timeStamp.getTime() : ''
    };
  });
  const mergedEventData = [...eventsListWithTimestamp, ...updatedEventsData].sort(
    (eventA, eventB) => {
      if (eventA.epochTime < eventB.epochTime) return -1;
      if (eventA.epochTime > eventB.epochTime) return 1;
      else return 0;
    }
  );

  const speed = mergedEventData.find((eventData) => eventData.speed)?.speed;
  if (saveWaterfallEventsSpeed) {
    saveWaterfallEventsSpeed(speed);
  }
  setEventsData(mergedEventData);
  return dataResponses;
};

/**
 * Function return whether structureborne datapoint selected or not
 * @param dataPointsList An array of DataPoint objects or undefined
 * @param dpid A number representing the dpid
 * @returns boolean
 */
export const isStructureborneSelected = (dataPointsList: DataPoint[] | undefined, dpid: number) => {
  // Find the dataPoint with the given dpid in the dataPointsList array
  const sensorType = dataPointsList?.find(
    (dataPoint: any) => dataPoint.dpid === Number(dpid)
  )?.sensor_type;
  if (sensorType === 'sns_mic_struct_borne') {
    return true;
  } else {
    return false;
  }
};

/**
 * A utility function to find the selected option from a list of options based on its value.
 * @param optionList An array of objects containing options, where each object has 'value' and 'label' properties.
 * @param selectedPoint The value of the selected option to be found in the optionList.
 * @returns The selected option object if found, or an empty string if not found.
 */
export const getSelectedOption = (
  optionList: {
    value: number;
    label: string;
  }[],
  selectedPoint: number
) => {
  const option = optionList.find((dataPoint) => {
    return dataPoint.value === selectedPoint;
  });
  return option || '';
};

/**
 * Function helps to standardize or convert a value based on the unit of frequency
 * @param value A number representing a value.
 * @param frequencyUnit A string indicating the unit of frequency.
 * @param speedValue  An optional number representing a speed value.

 * @returns converted unit value
 */
export function getEquivalentUnitValue(
  value: number,
  frequencyUnit: FREQUENCY_UNIT,
  speedValue: number | undefined
): number {
  switch (frequencyUnit) {
    case FREQUENCY_UNIT.CPM: {
      return value * 60;
    }
    case FREQUENCY_UNIT.Orders: {
      if (speedValue && speedValue > 0) {
        return value / speedValue;
      } else {
        return value;
      }
    }
    default: {
      return value;
    }
  }
}

/**
 * Function converts the min and max values of a zoom range
 * based on the provided frequency unit and speed value.
 * @param zoomValue Min and max zoom value.
 * @param frequencyUnit Unit of frequency.
 * @param speedValue Speed of event.
 * @returns zoomMin and zoomMax equivalent with unit.
 */
export function getZoomValuesOnUnitChange(
  zoomValue: { min: number; max: number },
  frequencyUnit: FREQUENCY_UNIT,
  speedValue: number | undefined
) {
  const zoomMin = getEquivalentUnitValue(zoomValue.min, frequencyUnit, speedValue);
  const zoomMax = getEquivalentUnitValue(zoomValue.max, frequencyUnit, speedValue);
  return { zoomMin, zoomMax };
}

/**
 * 
 * @param eventsData - Array of event data.
 * @param unit - The unit of frequency.
 * @param speed -  The speed of the event .
 * @returns The maximum value adjusted for the specified frequency unit and speed.

 */
export const getEventMaxValue = (
  eventsData: any,
  unit: FREQUENCY_UNIT,
  speed: number | undefined
) => {
  const eventsLength = eventsData[0]?.data?.length;
  // Retrieve the X value of the last data point from first event
  if (eventsLength) {
    const eventDataXValue = eventsData[0]?.data[eventsLength - 1][0];
    const eventDataMaxValue = getEquivalentUnitValue(eventDataXValue, unit, speed);
    return trimDecimals(eventDataMaxValue);
  } else {
    return 0;
  }
};

/**
 * Validates the maximum zoom values.
 * @param {Object} zoomValues - Object containing 'min' and 'max' zoom values.
 * @param {number | null} - The minimum zoom value (nullable).
 * @param {number | null} - The maximum zoom value (nullable).
 * @returns {boolean} - True if the validation passes, otherwise false.
 */
export function validateMaxZoomValues(zoomValues: { min: number | null; max: number | null }) {
  if (zoomValues.max === null) {
    return true;
  } else if (Number(zoomValues.min) < Number(zoomValues.max)) {
    return true;
  } else {
    return false;
  }
}

/**
 * Sort the events data based on the event date and time.
 * @param eventsData - Array of event data.
 * @returns
 */
export function sortByDateTime(eventsData: EventType[]) {
  const eventsListWithTimestamp = eventsData.map((event) => {
    //We're adding the epochTime value to sort data because the order of eventId may not always be ascending
    const timeStamp = new Date(`${event.event_date} ${event.event_time}`);

    return {
      ...event,
      ts: `${event.event_date} ${event.event_time}`,
      epochTime: timeStamp ? timeStamp.getTime() : 0
    };
  });
  const mergedEventData = [...eventsListWithTimestamp].sort((eventA, eventB) => {
    if (eventA.epochTime < eventB.epochTime) return -1;
    if (eventA.epochTime > eventB.epochTime) return 1;
    else return 0;
  });
  return mergedEventData;
}

/**
 * Transforms zoom values in Hz unit based on the specified frequency unitvalue.
 *
 * @param {Object} value - An object containing 'min' and 'max' zoom values.
 * @param {FREQUENCY_UNIT} frequencyUnit - The unit of frequency (e.g., CPM or Order).
 * @param {number | undefined} speedValue - Optional speed value for frequency unit 'Order'.
 * @returns {Object} - Transformed 'min' and 'max' zoom values based on the input parameters.
 */
export function getZoomValuesInHz(
  value: { min: number; max: number },
  frequencyUnit: FREQUENCY_UNIT,
  speedValue: number | undefined
): { min: number; max: number } {
  switch (frequencyUnit) {
    case FREQUENCY_UNIT.CPM: {
      return { min: value.min / 60, max: value.max / 60 };
    }
    case FREQUENCY_UNIT.Orders: {
      if (speedValue && speedValue > 0) {
        return { min: value.min * speedValue, max: value.max * speedValue };
      } else {
        return value;
      }
    }
    default: {
      return value;
    }
  }
}

/**
 * Transforms zoom values to a specific frequency unit  value.
 *
 * @param {Object} value - An object containing 'min' and 'max' zoom values.
 * @param {FREQUENCY_UNIT} frequencyUnit - The unit of frequency (e.g., CPM or Order).
 * @param {number | undefined} speedValue - Optional speed value for frequency unit 'Order'.
 * @returns {Object} - Transformed 'min' and 'max' zoom values based on the input parameters.
 */
export function convertZoomValuesToFrequencyUnit(
  value: { min: number; max: number },
  frequencyUnit: FREQUENCY_UNIT,
  speedValue: number | undefined
): { min: number; max: number } {
  switch (frequencyUnit) {
    case FREQUENCY_UNIT.CPM: {
      return { min: value.min * 60, max: value.max * 60 };
    }
    case FREQUENCY_UNIT.Orders: {
      if (speedValue && speedValue > 0) {
        return { min: value.min / speedValue, max: value.max / speedValue };
      } else {
        return value;
      }
    }
    default: {
      return value;
    }
  }
}

/**
 * Returns formatted string with the first letter of the first word and the rest of the string
 * @param inputString - String to be formatted
 * @returns Formatted string in the format of "First letter of the first word + rest of the string
 */
export function formatDatapointLabel(inputString: string) {
  // Split the string into an array of words
  const words = inputString.split(' ');
  // Extract the first letter of the first word
  const firstLetter = words[0].charAt(0);
  // Remove the first word from the array and join the rest back into a string
  const restOfString = words.slice(1).join(' ');
  // Combine the first letter and the rest of the string
  return firstLetter + ' ' + restOfString;
}

/**
 * Checks if eventsData is empty.
 * @param eventsData Array of events data.
 * @returns boolean indicating if eventsData is empty.
 */
export const isEventDataEmpty = (eventsData: eventsDataType[]) => {
  return eventsData.every((event) => Array.isArray(event.data) && event.data.length === 0);
};
