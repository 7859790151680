/**
 * Line Chart 3D using Highcharts Library
 */
import React, { Dispatch, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  Config3d,
  Y_AXIS_UNIT,
  ZoomValue,
  handleWaterfallYAxisExtremesValueChange,
  useWaterfallYUnit
} from '../../../features/workflow/trend-analysis/store';
import { useDispatch } from 'react-redux';
import { trimDecimals } from '../../helpers/number/decimalPrecision';
import { AnyAction } from 'redux';

interface Props {
  data: Array<any>;
  chartTitle: string;
  config3d: Config3d;
  chartRef: React.MutableRefObject<any>;
  xAxisZoomConfig: ZoomValue;
}

const highchartDefaultSettings = (
  config3d: Config3d,
  chartRef: React.MutableRefObject<any>,
  dispatch: Dispatch<AnyAction>,
  yAxisUnit: string
) => {
  const defaultSettings = {
    chart: {
      type: 'area',
      // zooming: {
      // type: 'x',
      // resetButton: {
      // position: {
      // align: 'right',
      // x: 0,
      // y: -35
      // },
      // theme: {
      // fill: '#21436C',
      // stroke: 'none',
      // style: {
      // fontWeight: '900',
      // /**
      // * we are hiding default reset zoom button
      // * as we are showing custom zoom reset button page
      // */
      // display: 'none'
      // }
      // }
      // }
      // },
      height: '35%',
      marginLeft: 0,
      marginRight: 0,
      options3d: {
        enabled: true,
        alpha: config3d.alpha,
        beta: config3d.beta,
        depth: config3d.depth,
        viewDistance: config3d.viewDistance,
        lineGap: config3d.lineGap,
        fitToPlot: true,
        frame: {
          bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
          back: { size: 1, color: 'rgba(0,0,0,0.04)' }
        }
      },
      events: {
        redraw: function () {
          const chart = chartRef?.current?.chart;
          const yAxis = chart?.yAxis[0];
          const extremes = yAxis?.getExtremes();
          const yNewValue = {
            min: trimDecimals(extremes?.dataMin),
            max: trimDecimals(extremes?.dataMax)
          };
          //Manually storing Y extremes value in redux, as it not updating in zoom file after chart render
          dispatch(handleWaterfallYAxisExtremesValueChange(yNewValue));
        }
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },

    tooltip: {
      useHTML: true,
      style: {
        width: 250,
        color: 'white'
      },
      positioner(): any {
        return {
          // @ts-ignore
          x: this.chart.plotLeft + (this.chart.plotWidth - 180),
          // @ts-ignore
          y: this.chart.plotTop + (this.chart.plotHeight - 60)
        };
      },
      formatter(): any {
        //@ts-ignore
        return 'Series: ' + this?.series?.name + '<br>';
      },
      shadow: false,
      borderWidth: 0,
      backgroundColor: 'rgba(77, 77, 77, 0.7)'
    },
    title: {
      text: 'Waterfall Chart'
    },
    xAxis: {
      min: 0
    },
    yAxis: {
      title: {
        text: yAxisUnit === Y_AXIS_UNIT.G ? 'G' : 'inches/second',
        margin: 70
      }
    },
    zAxis: {
      // categories: ["Series 1", "Series 2", "Series 3"],
      title: {
        text: 'Series'
      },
      labels: {
        enabled: true
      }
    },
    plotOptions: {
      area: {
        depth: config3d.lineGap || 50,
        color: '#0000FF',
        fillColor: 'transparent',
        marker: {
          radius: 2,
          states: {
            hover: {
              enabled: false,
              lineColor: '#ff0000',
              fillColor: '#ff0000'
            }
          }
        },
        states: {
          hover: {
            enabled: true,
            lineColor: '#ff0000',
            fillColor: '#ff0000'
          }
        }
      },
      states: {
        hover: {
          marker: {
            enabled: false
          }
        }
      }
    },
    series: []
  };
  return defaultSettings;
};
const parseSeries = (data: Array<any>) => {
  const series: Array<Highcharts.SeriesAreaOptions> = [];
  const xAxis: Array<Highcharts.XAxisOptions> = [];
  const yAxis: Array<Highcharts.YAxisOptions> = [];
  data.forEach((entry) => {
    series.push({
      name: entry.ts,
      description: entry.eventId,
      type: 'area',
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2
      },
      color: '#0000FF',
      fillColor: 'transparent',
      fillOpacity: 1,
      states: {
        inactive: {
          opacity: 0.7
        }
      },
      lineWidth: 1,
      opacity: 1,
      data: entry?.data || []
    });
    xAxis.push({
      visible: false,
      title: { text: entry.eventId, margin: 60 },
      min: 100
    });
  });
  return { series, xAxis, yAxis };
};
function setChartZoomHandler(xAxis: any) {
  xAxis.setExtremes(0, null, true);
}
const SLLineChart3DHC = React.memo(
  ({ data, chartTitle, config3d, chartRef, xAxisZoomConfig }: Props) => {
    const dispatch = useDispatch();
    const yAxisUnit = useWaterfallYUnit();

    // Memoized functions and objects
    const parsedData = useMemo(() => parseSeries(data), [data]);
    const defaultSettings = useMemo(
      () => highchartDefaultSettings(config3d, chartRef, dispatch, yAxisUnit),
      [config3d, chartRef, dispatch, yAxisUnit]
    );
    const chart = chartRef?.current?.chart;
    const xAxis = chart?.xAxis[0];
    const xExtremes = xAxis?.getExtremes();

    if (xExtremes) {
      setChartZoomHandler(xAxis);
    }
    useEffect(() => {
      if (xAxis && xAxisZoomConfig && chart) {
        xAxis.setExtremes(xAxisZoomConfig.min, xAxisZoomConfig.max, true);
      }
    }, [xAxis, xAxisZoomConfig, config3d, chart, data]);

    const onChartLoad = (chart: Highcharts.Chart) => {
      const xAxis = chart?.xAxis[0];
      if (xAxis && xAxisZoomConfig) {
        xAxis.setExtremes(xAxisZoomConfig.min || 0, xAxisZoomConfig.max || undefined, true);
      }
    };
    const settings = {
      ...defaultSettings,
      title: {
        text: chartTitle
      },
      series: parsedData.series,
      chart: {
        ...defaultSettings.chart,
        events: {
          ...defaultSettings.chart.events,
          load: function (this: Highcharts.Chart) {
            onChartLoad(this);
          }
        }
      }
      // xAxis: parsedData.xAxis
      // yAxis: parsedData.yAxis
    };
    return <HighchartsReact highcharts={Highcharts} ref={chartRef} options={settings} />;
  }
);

export default SLLineChart3DHC;
