import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum UserScriptActionTypes {
  SET_SCRIPT_NAME = 'SET_SCRIPT_NAME'
}

interface UserScriptInitialState {
  scriptName: string;
}

const initialState: UserScriptInitialState = {
  scriptName: 'untitled'
};

/**
 * This slice is used to manage the state of the user script.
 */
export const userScriptSlice = createSlice({
  name: 'userScript',
  initialState,
  reducers: {
    /**
     * Set script name.
     * @param state - The current state of the store.
     */
    setScriptName: (state, action: PayloadAction<{ scriptName: string }>) => {
      state.scriptName = action.payload.scriptName;
    }
  }
});

export const { setScriptName } = userScriptSlice.actions;

export default userScriptSlice.reducer;
