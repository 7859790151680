// /**
//  * Breadcrumb Reducers
//  * @author mahesh.kedari@shorelineiot.com
//  */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreadcrumbState } from './types/breadcrumbState';
import { BreadcrumbType } from './types/breadcrumbTypes';

const initialState: BreadcrumbState = {
  breadcrumbs: [],
  shouldMoveBreadcrumbToRight: {
    shouldMoveToRight: false,
    pixel: 0
  },
  collapseBreadcrumbConfig: {
    shouldBeCollapsable: false,
    collapseThresholdWidth: 0
  }
};

const pushToBreadcrumbHandler = (state: BreadcrumbState, action: PayloadAction<BreadcrumbType>) => {
  const { breadcrumbs } = state;
  const index = breadcrumbs.findIndex((br: BreadcrumbType) => {
    return action.payload.key === br.key;
  });
  if (index !== -1) {
    return {
      breadcrumbs: [...breadcrumbs.slice(0, index), action.payload, ...breadcrumbs.slice(index + 1)]
    };
  }
  state.breadcrumbs.push(action.payload);
};

const popFromBreadcrumbHandler = (state: BreadcrumbState, action: PayloadAction<string>) => {
  const newBreadcrumbs = state.breadcrumbs.filter((breadcrumb: BreadcrumbType) => {
    return breadcrumb.key !== action.payload;
  });
  state.breadcrumbs = newBreadcrumbs;
};

const resetBreadcrumbHandler = (state: BreadcrumbState): BreadcrumbState => {
  return {
    ...initialState,
    //NOTE: this is to maintain the previous state of the pixel value as most of  the useEffect return reset breacrumb state
    shouldMoveBreadcrumbToRight: {
      ...state.shouldMoveBreadcrumbToRight,
      pixel: state.shouldMoveBreadcrumbToRight.pixel
    }
  };
};

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    pushToBreadcrumb: (state, action: PayloadAction<BreadcrumbType>) => {
      pushToBreadcrumbHandler(state, action);
    },
    popFromBreadcrumb: (state, action: PayloadAction<string>) => {
      popFromBreadcrumbHandler(state, action);
    },
    resetBreadcrumb: (state) => resetBreadcrumbHandler(state),
    moveBreadcrumbToRight: (
      state,
      action: PayloadAction<{
        shouldMoveToRight: boolean;
        pixel?: number;
      }>
    ) => {
      state.shouldMoveBreadcrumbToRight = {
        shouldMoveToRight: action.payload.shouldMoveToRight,
        pixel:
          action.payload.pixel !== undefined
            ? action.payload.pixel
            : state.shouldMoveBreadcrumbToRight.pixel
      };
    },
    setCollapsableConfig: (
      state,
      action: PayloadAction<{
        shouldBeCollapsable?: boolean;
        collapseThresholdWidth?: number;
      }>
    ) => {
      state.collapseBreadcrumbConfig = {
        shouldBeCollapsable:
          action.payload.shouldBeCollapsable !== undefined
            ? action.payload.shouldBeCollapsable
            : state.collapseBreadcrumbConfig.shouldBeCollapsable,
        collapseThresholdWidth:
          action.payload.collapseThresholdWidth !== undefined
            ? action.payload.collapseThresholdWidth
            : state.collapseBreadcrumbConfig.collapseThresholdWidth
      };
    }
  }
});

export const {
  pushToBreadcrumb,
  popFromBreadcrumb,
  resetBreadcrumb,
  moveBreadcrumbToRight,
  setCollapsableConfig
} = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
