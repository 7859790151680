import { Series, SeriesOptionsType, YAxisOptions } from 'highcharts';
import { rawDataTooltipFormatter } from './rawDataTooltipFormatter';
import {
  TOGGLE_ID,
  TOGGLE_LEGEND
} from '../../../../features/workflow/vibration-analysis/va.constants';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { chartXAxisLabelFormatter } from '../../../helpers';
import { millisecondsTimeFormatter } from './millisecondsTimeFormatter';
import { microsecondsTimeFormatter } from './microsecondsTimeFormatter';

/**
 * Handle Chart configuration for Raw data widget
 * @param resultSet - The dataset to be visualized
 * @param type - The type of chart (e.g., 'line')
 * @param configChartSettings - Additional chart configuration settings
 * @param hideSeries - Set of series names to be hidden initially
 * @returns An object containing chart configuration options
 */
export const rawDataCartesianParser = (
  resultSet: any,
  type: any,
  dispatch: Dispatch<AnyAction>,
  configChartSettings?: any,
  hideSeries?: Set<string>,
  setHideSeries?: React.Dispatch<React.SetStateAction<Set<string>>>,
  isDataInMilliseconds?: boolean
) => {
  // Configure x-axis for datetime display
  const xAxis = {
    type: 'datetime',
    labels: {
      autoRotation: false,
      formatter: isDataInMilliseconds ? millisecondsTimeFormatter : microsecondsTimeFormatter,
      ...chartXAxisLabelFormatter
    }
  };

  // Determine if a single y-axis should be used for all series
  // Initializing singleYAxis to false so that the yAxis array gets correctly populated -
  // solved single Y axis line chart error: https://shorelineiot.atlassian.net/browse/SLC-9698
  const singleYAxis = false;

  // Configure tooltip
  const tooltip = {
    useHTML: true,
    formatter: function (this: Highcharts.TooltipFormatterContextObject) {
      return rawDataTooltipFormatter.call(this, isDataInMilliseconds);
    },
    style: {
      color: 'white'
    },
    backgroundColor: 'rgba(77, 77, 77, 0.7)'
  };

  const handleLegendClick = (instance: Series) => {
    if (setHideSeries !== undefined && hideSeries !== undefined) {
      const { name, userOptions, chart } = instance;
      const legends: (Highcharts.Series | Highcharts.Point)[] = chart?.legend.allItems;
      if (
        (userOptions?.id === TOGGLE_ID && userOptions?.visible === true) ||
        userOptions?.visible === undefined
      ) {
        setHideSeries(new Set());
        return true;
      } else if (userOptions?.id === TOGGLE_ID && userOptions?.visible === false) {
        const legendsToHide = legends.map((legend: any) => {
          const legendName = legend?.name;
          if (legendName) {
            return legendName;
          }
        });
        setHideSeries(new Set(legendsToHide.filter(Boolean)));
        return true;
      }
      //Remove the legend from the hideSeries if it is already there
      if (hideSeries.has(name)) {
        const newSeriesToHide = new Set(hideSeries);
        newSeriesToHide.delete(name);
        //if the only legend is hide/show all, then remove it
        if (newSeriesToHide.size === 1 && newSeriesToHide.has(TOGGLE_LEGEND.name)) {
          newSeriesToHide.delete(TOGGLE_LEGEND.name);
        }
        setHideSeries(newSeriesToHide);
      }
      //hide a new legend if it is not already there
      else if (!hideSeries.has(name)) {
        const newSeriesToHide = new Set(hideSeries);
        newSeriesToHide.add(name);
        //if all the legends except show/hide all, then add it
        if (
          newSeriesToHide.size === legends.length - 1 &&
          !newSeriesToHide.has(TOGGLE_LEGEND.name)
        ) {
          newSeriesToHide.add(TOGGLE_LEGEND.name);
        }
        setHideSeries(newSeriesToHide);
      }
      return true;
    }
  };

  // Initialize series array, including toggle legend for line charts
  const series: Array<SeriesOptionsType> =
    type === 'line'
      ? [
          {
            type: 'line',
            id: TOGGLE_ID,
            name: 'Show All / Hide All',
            showInLegend: true,
            visible: hideSeries ? !hideSeries.has(TOGGLE_LEGEND.name) : true,
            events: {
              legendItemClick: function (event) {
                // Prevent default action on shift+click event
                if (event.browserEvent.shiftKey) {
                  return false;
                }
                handleLegendClick(this);
                // Let Highcharts handle the default action
                return true;
              }
            }
          }
        ]
      : [];
  const yAxis: Array<YAxisOptions> = [];
  /**
   * Map to track which units have been assigned to which y-axis.
   */
  const unitToAxisMap: { [key: string]: number } = {};
  /**
   * Map to track visibility of series for each unit.
   */
  const unitVisibilityMap: { [key: string]: number } = {};

  // Process each result in the dataset
  resultSet?.forEach((result: any) => {
    const seriesName = `${result?.device?.name} - ${result?.datapoint?.dp_name}`;
    const isVisibleSeries = hideSeries ? !hideSeries.has(seriesName) : true;
    const unit = result?.datapoint?.unit || 'value';

    // Determine which y-axis this series should use
    // Initializing yAxis so that it always has a value even if singleYAxis prop is undefined
    let yAxisIndex = 0;
    if (singleYAxis) {
      yAxisIndex = 0;
    } else if (unit in unitToAxisMap) {
      // Use existing y-axis for this unit
      yAxisIndex = unitToAxisMap[unit];
    } else {
      // Create new y-axis for this unit
      yAxisIndex = Object.keys(unitToAxisMap).length;
      unitToAxisMap[unit] = yAxisIndex;
      const yAxisConfig = {
        title: { text: unit },
        visible: isVisibleSeries,
        opposite: yAxisIndex % 2 !== 0,
        min: configChartSettings.manualScaleYAxis
          ? configChartSettings.yAxisMin === ''
            ? 0
            : Number(configChartSettings.yAxisMin)
          : undefined,
        max: configChartSettings.manualScaleYAxis
          ? configChartSettings.yAxisMax === ''
            ? null
            : Number(configChartSettings.yAxisMax)
          : undefined,
        plotLines: [] as Highcharts.YAxisPlotLinesOptions[]
      };

      configChartSettings?.plotLines?.forEach((plotLineConfig: any) => {
        if (plotLineConfig?.selectSensorsAxis === seriesName) {
          yAxisConfig.plotLines.push({
            value: Number(plotLineConfig?.value),
            color: plotLineConfig.color?.hex || '#ff0000',
            width: plotLineConfig?.thickness,
            dashStyle: plotLineConfig?.dashStyle,
            label: {
              text: plotLineConfig?.label,
              align: 'right',
              verticalAlign: 'middle',
              style: {
                color: plotLineConfig.color?.hex || '#ff0000'
              }
            }
          });
        }
      });

      yAxis.push(yAxisConfig);
    }
    // Track visibility for the unit
    if (!unitVisibilityMap[unit]) {
      unitVisibilityMap[unit] = 0;
    }
    if (isVisibleSeries) {
      unitVisibilityMap[unit]++;
    }

    // Add the series to the chart
    series.push({
      type,
      data: [...(result?.data || [])]
        ?.sort((a: Array<any>, b: Array<any>) => a[0] - b[0])
        ?.map((datapointResult: Array<any>) => [
          // Zeroth element is always going to be a number & just
          // added Number function for the 1st element for the
          // special case, where sometime data appears as a string.
          datapointResult[0],
          Number(datapointResult[1])
        ]),
      name: seriesName,
      yAxis: yAxisIndex,
      custom: {
        datapoint: result?.datapoint
      },
      visible: isVisibleSeries,
      events: {
        /**
         * Event handler for when the legend item is clicked.
         * Updates the visibility of the y-axis based on the visibility of the series.
         */
        legendItemClick: function (event) {
          // Prevent default action on shift+click event
          if (event.browserEvent.shiftKey) {
            return false;
          }

          // Update the unit visibility count based on the new series visibility state
          const visible = this?.visible ? -1 : 1;
          unitVisibilityMap[unit] += visible;

          // Update yAxis visibility based on unitVisibilityMap
          this?.chart?.yAxis[yAxisIndex]?.update({ visible: unitVisibilityMap[unit] > 0 }, false);

          handleLegendClick(this);
          // Let Highcharts handle the default action
          return true;
        }
      }
    });
  });

  // Set initial yAxis visibility based on unitVisibilityMap
  yAxis.forEach((axis, index) => {
    const unit = Object.keys(unitToAxisMap)?.find((key) => unitToAxisMap[key] === index) || '';
    axis.visible = unitVisibilityMap[unit] > 0;
  });

  // Return the complete chart configuration
  return { xAxis, tooltip, series, yAxis };
};
