/**
 * Breadcrumb
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import RightIcon from '@mui/icons-material/ChevronRight';
import { useSlugContext } from '../../lib/slugContext';
import { useStyles } from './breadcrumb.styles';
import { BreadcrumbType } from './types/breadcrumbTypes';
import {
  useCollapseBreadcrumbConfig,
  useBreadcrumbState,
  useShouldMoveBreadcrumbToRightPixel,
  useShouldMoveBreadcrumbToRightState
} from './breadcrumb.selectorHooks';
import { Skeleton, Box } from '@mui/material';

/**
 *
 */
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}
/**
 *
 * @param props
 */
const LinkRouter = (props: LinkRouterProps) => {
  return <Link {...props} component={RouterLink as any} />;
};

const BreadcrumbSkeleton = () => {
  return <Skeleton variant="text" width={80} />;
};

/**
 *
 */
const SLBreadcrumb: React.FC = () => {
  const location = useLocation();
  const { slug } = useSlugContext();
  const breadcrumbsRef = useRef<any>(null);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const SINGLE_LINE_HEIGHT = 25;
  const breadcrumbsList: Array<BreadcrumbType> = useBreadcrumbState();
  const buttonWidth = useShouldMoveBreadcrumbToRightPixel();
  const shouldMoveToRight = useShouldMoveBreadcrumbToRightState();
  const { collapseThresholdWidth, shouldBeCollapsable } = useCollapseBreadcrumbConfig();
  const classes = useStyles({ buttonWidth });

  const getPath = (index: number) => {
    const subset = breadcrumbsList.slice(0, index + 1);
    const path = subset.reduce((accumulator: string, breadcrumb: BreadcrumbType) => {
      if (breadcrumb?.isAbsolute) {
        return `${breadcrumb.link}`;
      }
      if (breadcrumb?.concatenate) {
        return `${accumulator}${breadcrumb?.concatenate}${breadcrumb.link}`;
      }
      return `${accumulator}/${breadcrumb.link}`;
    }, '');
    return path;
  };

  useEffect(() => {
    const checkBreadcrumbOverflow = () => {
      if (breadcrumbsRef.current && collapseThresholdWidth) {
        const breadcrumbContentWidth = breadcrumbsRef.current.scrollWidth;
        const parentWidth = breadcrumbsRef.current.parentElement?.clientWidth || 0;
        // Calculate available space after rendering breadcrumbs.
        const remainingSpace = parentWidth - breadcrumbContentWidth;

        // If there's not enough space (or negative space), mark as collapsed.
        setIsCollapsed(remainingSpace < collapseThresholdWidth);
      }
    };

    // Initial check on mount and when collapseThresholdWidth changes.
    checkBreadcrumbOverflow();

    // Update on window resize.
    window.addEventListener('resize', checkBreadcrumbOverflow);
    return () => {
      window.removeEventListener('resize', checkBreadcrumbOverflow);
    };
  }, [collapseThresholdWidth, breadcrumbsList]);

  return (
    <Breadcrumbs
      className={`${
        // Below collapsedBreadcrumbs related change has been added for the Asset Details page.
        breadcrumbsRef?.current?.parentElement?.clientHeight > SINGLE_LINE_HEIGHT &&
        breadcrumbsList?.length > 3
          ? classes.collapsedBreadcrumbs
          : classes.breadcrumbs
        // Below collapsedBreadcrumbs related change has been added for the Powertrains page.
      } ${shouldBeCollapsable && isCollapsed ? classes.collapsedBreadcrumbs : ''}`}
      sx={{
        marginLeft: shouldMoveToRight ? `${buttonWidth ? buttonWidth : 0}px` : ''
      }}
      ref={breadcrumbsRef}
      separator={<RightIcon />}
      aria-label="breadcrumb">
      {breadcrumbsList?.map((breadcrumb, index) => {
        const last = index === breadcrumbsList?.length - 1;
        const to = `/${slug}/app${getPath(index)}`;
        const { isLoading, link, Component } = breadcrumb;
        return last && Component ? (
          <span key={to}>
            {isLoading ? (
              <BreadcrumbSkeleton />
            ) : (
              <Box display="flex" alignItems="center" gap={1}>
                {breadcrumb.title} <Component />
              </Box>
            )}
          </span>
        ) : last ? (
          <span key={to}>{isLoading ? <BreadcrumbSkeleton /> : breadcrumb.title}</span>
        ) : isLoading ? (
          <BreadcrumbSkeleton />
        ) : link ? (
          <LinkRouter underline="hover" to={to} key={to}>
            {breadcrumb.title}
          </LinkRouter>
        ) : (
          <span key={to}>{breadcrumb.title}</span>
        );
      })}
      {JSON.stringify(location)}
    </Breadcrumbs>
  );
};

export default SLBreadcrumb;
