import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { SortingState } from '../../../framework/components/table/slTable.types';
import { useFetchDeviceGroups } from '../../device';
import usePowertrainsUserGroups from '../../device/new-asset-dashboard/custom-hooks/usePowertrainsUserGroups';
import { usePowertrainUserGroupsFilterSelector } from '../../device/store/device.slice.hooks';
import {
  Alarm,
  ALARM_TYPE_OPTIONS,
  ALARM_URL_PARAMS,
  AlarmSeverityType,
  ParamsString
} from '../store';
import { useQuery, useSlugContext } from '../../../framework';

export const DEFAULT_ALARMS_SORTING: SortingState = [{ id: 'latestColumnData', desc: true }];

export const calculateTableHeightDifference = (selectedRows: string[]): string =>
  `calc(100vh - ${selectedRows?.length ? '275px' : '245px'})`;

export const formatTriggerValueData = (alarm: Alarm, placeholder = '-'): string => {
  const isTriggerValuePresent = typeof alarm?.trigger_value === 'number';

  const triggerValue = isTriggerValuePresent ? alarm?.trigger_value : placeholder;
  const thresholdValue =
    typeof alarm?.threshold_value === 'number' ? alarm?.threshold_value : placeholder;
  const unit = alarm?.unit || placeholder;

  return isTriggerValuePresent ? `${triggerValue} (> ${thresholdValue}) ${unit}` : placeholder;
};

export const getAlarmsBreadcrumbTitle = (
  isHistoricalAlarmsSelectedInfo: boolean,
  alarmTypeFromURL: string
) => {
  const alarmPage = isHistoricalAlarmsSelectedInfo ? 'Historic' : 'Active';
  const alarmType =
    alarmTypeFromURL === ALARM_TYPE_OPTIONS.ASSET.value ? 'Asset Alarms' : 'Sensor Alarms';

  return `${alarmType} > ${alarmPage}`;
};

export const getAlarmsBreadcrumbLink = (
  isHistoricalAlarmsSelectedInfo: boolean,
  alarmFiltersFromURL: ParamsString
) => {
  const { alarm_type, path, severity, name, state, modifiedBy, occurred, site } =
    alarmFiltersFromURL;
  const alarmPage = isHistoricalAlarmsSelectedInfo ? 'historical' : '';
  let link = 'alarms';
  if (alarmPage.length) {
    link += `/${alarmPage}`;
  }
  link += alarm_type ? `?alarm_type=${alarm_type}` : '';
  link += severity ? `&severity=${severity}` : '';
  link += path ? `&path=${path}` : '';
  link += name ? `&name=${name}` : '';
  link += state ? `&state=${state}` : '';
  link += modifiedBy ? `&modifiedBy=${modifiedBy}` : '';
  link += occurred ? `&occurred=${occurred}` : '';
  link += site ? `&site=${site}` : '';
  return link;
};

/**
 * Returns the bookmarkable parameter for a given column accessor key.
 * This function maps column accessor keys to their corresponding bookmarkable parameters.
 * @param {string} columnAccessorKey - The column accessor key.
 * @returns {string} The bookmarkable parameter.
 */
export const getBookmarkableParamForColumnAccessorKey = (columnAccessorKey: string): string => {
  switch (columnAccessorKey) {
    case 'severity':
      return ALARM_URL_PARAMS.SEVERITY;
    case 'stateColumnData':
      return ALARM_URL_PARAMS.STATE;
    case 'stateColumnData.modifiedByUserName':
      return ALARM_URL_PARAMS.MODIFIED_BY;
    case 'siteColumnData':
      return ALARM_URL_PARAMS.SITE;
    case 'pathName':
      return ALARM_URL_PARAMS.PATH;
    case 'occurrence_count':
      return ALARM_URL_PARAMS.OCCURRED;
    default:
      return '';
  }
};

interface UserGroupDevicePaths {
  userGroupDevicePaths: string;
}

/**
 * This custom-hook is used to get the user assigned groups device paths.
 *
 * When `isPowertrainUserGroupsFilterApplied` is `true` (by default), it returns
 * the device paths of the user assigned groups. Otherwise, it returns an empty.
 * @returns `UserGroupDevicePaths` - The device paths of the user assigned groups.
 */
export const useGetUserGroupDevicePaths = (): UserGroupDevicePaths => {
  const { slug } = useSlugContext();
  const isPowertrainUserGroupsFilterApplied = usePowertrainUserGroupsFilterSelector();
  const { usersGroup } = usePowertrainsUserGroups();

  const urlParams = useQuery();

  const { deviceGroupsMap } = useFetchDeviceGroups(slug ? { slug } : skipToken);

  // Collect all device IDs, filter out null or invalid values, and join them into a string
  const userGroupDevicePaths = useMemo(() => {
    /**
     * Do not apply the `path` filter if the `alarm_id` is present on the alarms table page URL.
     */
    if (!urlParams?.get(ALARM_URL_PARAMS.ALARM_ID) && usersGroup?.length) {
      const groupPaths = usersGroup?.map((group) => group.asset_id);

      return groupPaths
        .reduce((acc: number[], groupPath) => {
          const deviceIds = deviceGroupsMap?.[groupPath]?.device_ids;
          if (deviceIds?.length) {
            acc.push(...deviceIds.filter((id) => id !== null)); // Filter out null values
          }
          return acc;
        }, [])
        .join(',');
    }

    return '';
  }, [deviceGroupsMap, usersGroup]);

  return {
    userGroupDevicePaths: isPowertrainUserGroupsFilterApplied ? userGroupDevicePaths : ''
  };
};

/**
 * Maps an alarm severity type to a user-friendly name.
 *
 * @param severity - The severity type (e.g., 'ALERT_FATAL') or null.
 * @returns A readable string (e.g., 'Critical') or an empty string if invalid.
 */
export const getSeverityName = (severity: AlarmSeverityType | null) => {
  switch (severity) {
    case 'ALERT_FATAL':
      return 'Critical';
    case 'ALERT_ERROR':
      return 'High';
    case 'ALERT_WARNING':
      return 'Moderate';
    case 'ALERT_LOW':
      return 'Low';
    default:
      return '';
  }
};

/**
 * Converts an array into a URL query parameter string.
 * @param values - The array of values to be transformed.
 * @returns A URL-encoded comma-separated string.
 */
export const convertArrayToQueryParam = (values?: string[]): string => {
  if (!values || values.length === 0) return '';
  return values.map(encodeURIComponent).join(',');
};
