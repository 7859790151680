import React, { useEffect } from 'react';
import _ from 'lodash';
import { MRT_ColumnDef } from 'material-react-table';
import { useState } from 'react';
import MultiSelectDropdown from '../dropDown/MultiSelectDropdown';
import { ColumnFilter, SLTableColumn } from './slTable.types';
import { Box } from '@mui/material';
import { findTitlesForValues } from './sltable.utils';

const FILTER_DROPDOWN_WIDTH = '275px';
const MaterialColumns = (
  columns: SLTableColumn<any>[],
  columnFiltersValues: ColumnFilter[],
  setColumnFiltersValues: React.Dispatch<React.SetStateAction<ColumnFilter[]>>,
  areAllMultiSelectOptionsValid: boolean,
  feature: string
) => {
  const [defaultValues, setDefaultValues] = useState<Record<string, string[]>>(() => {
    // Extract default values from column objects and merge them
    const initialValues: Record<string, any> = {};
    columns.forEach((columnObj) => {
      const columnId = columnObj.accessorKey as string;
      if (columnObj.defaultFilterValue) {
        initialValues[columnId] = columnObj.defaultFilterValue;
      }
    });
    return initialValues;
  });

  // Set columnFilters as default values
  useEffect(() => {
    if (areAllMultiSelectOptionsValid) {
      const initialValues: Record<string, any> = {};
      columns.forEach((columnObj) => {
        const columnId = columnObj.accessorKey as string;
        const columnFiltersData = columnFiltersValues?.find((columnFilterValue) => {
          return columnFilterValue.id === columnId;
        });
        if (columnFiltersData?.value) {
          /**
           * If column has filterValues then use it else use columnFiltersData.value
           */
          initialValues[columnId] = columnObj?.filterValues
            ? columnObj?.filterValues
            : columnFiltersData?.value;
        }
      });
      setDefaultValues(initialValues);
    }
  }, [columnFiltersValues, columns, areAllMultiSelectOptionsValid]);

  const materialColumns: Array<MRT_ColumnDef<any>> = columns.map(
    (columnObj: SLTableColumn<any>) => {
      const columnId = columnObj.accessorKey as string;
      return {
        Cell: ({ cell, row, column, table }) =>
          columnObj?.CellRenderer
            ? columnObj.CellRenderer({
                cellValue: cell.getValue(),
                rowObj: row.original,
                row: row,
                column,
                table
              })
            : null,
        renderColumnFilterModeMenuItems: columnObj.renderColumnFilterModeMenuItems,
        Edit: columnObj.Edit,
        header: columnObj.header,
        accessorFn: columnObj.accessorFn,
        accessorKey: columnObj.accessorKey,
        size: columnObj.size || columnObj?.header?.length * 17 + 28,
        disableCellClick: Boolean(columnObj.disableCellClick),
        muiTableBodyCellProps: columnObj.muiTableBodyCellProps,
        enableSorting: columnObj.enableSorting === undefined ? true : !!columnObj.enableSorting,
        sortDescFirst: !!columnObj.sortDescFirst,
        ...(columnObj?.sortingFn && { sortingFn: columnObj?.sortingFn }),
        Header: columnObj.Header,
        enableColumnFilter: columnObj.enableColumnFilter === false ? false : true,
        filterVariant:
          // this condition is added to avoid ts error, the filter variant
          // component is overridden on 473th line using the Filter prop for multi-select-with-search
          columnObj?.filterVariant === 'multi-select-with-search'
            ? 'select'
            : columnObj?.filterVariant,
        filterFn: columnObj.filterFn,
        filterSelectOptions: columnObj.filterSelectOptions,
        enableColumnFilterModes: columnObj.enableColumnFilterModes === false ? false : true,
        Filter:
          columnObj?.filterVariant === 'multi-select-with-search'
            ? ({ header }) => {
                const options = columnObj.multiSelectWithSearchOptions || [];
                const onMultiSelectOptionChange = (selectedData: string[]) => {
                  setDefaultValues((prevValues) => ({
                    ...prevValues,
                    [columnId]: selectedData
                  }));
                  header.column.setFilterValue(findTitlesForValues(selectedData, options));
                  setColumnFiltersValues((prevValues) => {
                    const index = prevValues.findIndex((item) => item.id === columnId);
                    if (index !== -1) {
                      // If columnId exists, update its value
                      const updatedValues = [...prevValues];
                      updatedValues[index] = { id: columnId, value: selectedData };
                      return updatedValues;
                    } else {
                      // If columnId doesn't exist, add a new object
                      return [...prevValues, { id: columnId, value: selectedData }];
                    }
                  });

                  if (columnObj?.onBlurMultiSelect) {
                    columnObj?.onBlurMultiSelect(selectedData);
                  }
                };
                return (
                  <Box
                    sx={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      position: 'relative',
                      verticalAlign: 'top',
                      width: FILTER_DROPDOWN_WIDTH
                    }}>
                    <MultiSelectDropdown
                      dataTestId={`${feature}-filter-tree-select-input`}
                      treeData={options?.sort((a, b) => a?.title?.localeCompare(b?.title))}
                      onTagCloseButton={(selectedData: string[]) => {
                        onMultiSelectOptionChange(selectedData);
                      }}
                      defaultValue={defaultValues[columnId] || []}
                      defaultOpenDropdownMenu={columnObj?.defaultOpenFilterDropdownMenu}
                      treeDefaultExpandAll={columnObj?.defaultExpandFilterDropdownMenu}
                      isCustomTag
                      treeCheckable={true}
                      placeholder={columnObj.placeholderForFilterDropdown}
                      onBlurDropdown={(selectedData: string[]) => {
                        onMultiSelectOptionChange(selectedData);
                      }}
                      titleTag
                      bordered={'LINE'}
                      value={defaultValues[columnId] || []}
                      dropdownStyle={{ width: FILTER_DROPDOWN_WIDTH }}
                    />
                  </Box>
                );
              }
            : columnObj.Filter,
        enableEditing: columnObj.enableEditing === undefined ? true : !!columnObj.enableEditing,
        enableHiding: columnObj.enableHiding === undefined ? true : !!columnObj.enableHiding,
        muiEditTextFieldProps: columnObj.muiEditTextFieldProps,
        muiFilterTextFieldProps: columnObj?.muiFilterTextFieldProps,
        minSize: columnObj.minSize || columnObj?.header?.length * 17 + 28,
        maxSize: columnObj.maxSize,
        enableColumnActions: columnObj.enableColumnActions,
        columnFilterModeOptions: columnObj.columnFilterModeOptions,
        enableClickToCopy: columnObj.enableClickToCopy,
        enableResizing: columnObj.enableResizing
      };
    }
  );

  return materialColumns;
};

export default MaterialColumns;
