/**
 *
 */

import { BreadcrumbType } from './breadcrumbTypes';

export type BreadcrumbState = {
  breadcrumbs: Array<BreadcrumbType>;
  /**
   * This property is used when there's a need to reserve space for a custom icon action
   * (or any additional element) that might be rendered before or after the breadcrumb.
   *
   * When `shouldMoveToRight` is set to `true`, the breadcrumb component will be shifted
   * to the right by the specified `pixel` value (commonly 80px). This creates a reserved area
   * for the extra element. The extra element can then be styled independently (for example,
   * positioned with a negative margin-top) to visually align with the breadcrumb.
   */
  shouldMoveBreadcrumbToRight: {
    shouldMoveToRight: boolean;
    pixel: number;
  };
  /**
   * Configuration to control the collapsibility of the breadcrumb.
   *
   * In certain pages, additional elements (such as a custom icon, button, or other UI element)
   * may be present alongside the breadcrumb. This configuration provides a threshold width
   *  which defines the maximum tolerated width for the breadcrumb before it should collapse.
   *
   * **Usage Context:**
   * - When the actual rendered width of the breadcrumb content exceeds the available space
   *   (after accounting for the extra UI elements), the breadcrumb should collapse.
   * - The `collapseThresholdWidth` property gives developers control over the tolerance,
   *   ensuring that the breadcrumb does not overlap or interfere with the adjacent elements.
   *
   * **Property Details:**
   * - `shouldBeCollapsable`: Determines if the breadcrumb should have the option to collapse.
   * - `collapseThresholdWidth`: The threshold width (in pixels) used to decide if the breadcrumb
   *    is too long and needs to collapse. This replaces the previous `customWidth` name to more
   *    clearly indicate its purpose.
   */
  collapseBreadcrumbConfig: {
    shouldBeCollapsable: boolean;
    collapseThresholdWidth: number;
  };
};

export enum BREADCRUMB_ACTION {
  ADD = 'add',
  REMOVE = 'remove'
}
