/**
 * Multiline Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Data from 'highcharts/modules/data';
import React, { createRef, useEffect, useImperativeHandle, useState } from 'react';
import merge from 'lodash/merge';
import { Box, Typography } from '@mui/material';
import { ChartViewProps } from '../common/ChartViewProps';
import { syncExtremes } from '../utils/syncExtremes';
import { createRawDataStackchartSeries } from './createStackchartSeries';
import { DASHBOARD_ITEM_TYPE } from '../../dashboard-widgets/types/DashboardItemType';

// For Date formatter we need to wrap highchart with its data module
Data(Highcharts);
interface InnerComponentProps {
  chartRefs: Array<any>;
}
/**
 *
 */
const SLStacklineChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet, widgetType }: ChartViewProps, ref: any) => {
    const [updatedChartSettings, setUpdatedChartSettings] = useState<Array<any>>([]);
    const [elRefs, setElRefs] = React.useState<Array<any>>([]);
    // Inner component is created to avoid closure inside useImperativeHandle for chart references.
    // Now below component will rerender whenever elRefs gets changed. Since it is returning null, and it is memoized
    // based on chart refs length, it will have a minimum impact in terms of performance.
    const ChartResizeHandler = React.memo(
      ({ chartRefs }: InnerComponentProps) => {
        useImperativeHandle(ref, () => {
          const allChartRefs = chartRefs;
          return {
            resize() {
              allChartRefs.forEach((elRef: any) => elRef?.current?.chart?.reflow());
            }
          };
        }, []);
        return null;
      },
      (prevProps, nextProps) => prevProps?.chartRefs?.length === nextProps?.chartRefs?.length
    );

    /**
     * Checks the data compatiblity by checking if `number`
     * data type exists or not.
     * @param resultSetData Response Data
     * @returns `false` if data type is compatible (i.e. `number` is present), else `true`.
     */
    const checkIfIncompatibileData = (resultSetData: any[]) => {
      const isNotCompatible = resultSetData?.find((result: any) => {
        if (result) {
          const dataArray: Array<any> = result?.data;
          if (dataArray?.length) {
            // If non-numerical (Incompatible) data occured, then show
            // toast to user instead of crash.
            const isNonNumericalDataFound: any[] = dataArray?.find(
              (data: any) =>
                (data?.length === 2 && typeof data[1] !== 'number') ||
                (data?.length && typeof data[0] !== 'number')
            );
            if (isNonNumericalDataFound && isNonNumericalDataFound?.length) {
              return true;
            }
          }
        }
        return null;
      });
      if (isNotCompatible) {
        return true;
      }
      return false;
    };

    useEffect(() => {
      const allSeries: Array<any> = [];

      let minDate: Date;
      let maxDate: Date;
      const chartRefs: Array<any> = [];
      if (widgetType === DASHBOARD_ITEM_TYPE.RAW_DATA) {
        resultSet.forEach((result: any, index: number) => {
          chartRefs.push(elRefs[index] || createRef());
          if (result) {
            const dataArray: Array<any> = result?.data;
            dataArray?.sort((a, b) => a[0] - b[0]);
            // Check Min and Max
            if (!minDate || minDate.getTime() > new Date(dataArray[0][0]).getTime()) {
              minDate = new Date(dataArray[0][0]);
            }
            if (
              !maxDate ||
              maxDate.getTime() < new Date(dataArray[dataArray.length - 1][0]).getTime()
            ) {
              maxDate = new Date(dataArray[dataArray.length - 1][0]);
            }
            const series: Highcharts.Options = createRawDataStackchartSeries(
              `${result.device?.name}-${result.datapoint?.dp_name}`,
              dataArray,
              resultSet.length,
              result.datapoint
            );
            allSeries.push(series);
          }
        });
      }
      // Update Charts Reference Array
      setElRefs(chartRefs);
      /* 
      Set Min and Max for x-axis based on min-max of all the series. 
      This is done for showing same range on x-axis for all the charts even if data 
      fetched for any series is not available for full range. By default highcharts
      automatically sets extremes based on data of that specific series
       */
      const seriesWithMinMax = allSeries.map((series: Highcharts.Options, index: number) =>
        merge<Highcharts.Options, Highcharts.Options>(series, {
          ...(chartSettings?.chartHeight && {
            chart: {
              height: chartSettings?.chartHeight || null
            }
          }),
          xAxis: {
            min: minDate?.getTime(),
            max: maxDate?.getTime(),
            events: {
              afterSetExtremes: (event: any) => {
                if (event.trigger !== 'syncExtremes') {
                  syncExtremes(event, chartRefs, index);
                }
              }
            }
          }
        })
      );
      setUpdatedChartSettings(seriesWithMinMax);
    }, [resultSet, chartSettings]);

    const handleMouseMove: React.MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
      try {
        elRefs?.forEach((refInstance: any) => {
          // Find coordinates within the chart
          const chartInstance = refInstance?.current?.chart;
          if (chartInstance) {
            const event = chartInstance?.pointer?.normalize(e);
            // Get the hovered point
            const point = chartInstance?.series[0]?.searchPoint(event, true);
            // point?.select(true, false);
            point?.setState('hover');
            chartInstance?.tooltip?.refresh(point, e);
          }
        });
      } catch (error: any) {
        // empty function
      }
      // e.stopPropagation();
      // }
    };

    if (checkIfIncompatibileData(resultSet)) {
      // non-compatible
      return (
        <Box>
          <Typography variant="body1">Incompatible data for selected chart type.</Typography>
        </Box>
      );
    }

    return (
      <Box onMouseMove={handleMouseMove}>
        {updatedChartSettings?.map((setting, index) => (
          <HighchartsReact
            highcharts={Highcharts}
            options={setting}
            ref={elRefs[index]}
            key={setting?.chartId}
          />
        ))}
        <ChartResizeHandler chartRefs={elRefs} />
      </Box>
    );
  }
);

export default SLStacklineChartView;
