/**
 * Create Stackchart Series
 * @author mahesh.kedari@shorelineiot.com
 */
import { merge } from 'lodash';
import { multilineChartConfig as defaultSettings } from './stacklineChartSettings';
import { microsecondsTimeFormatter } from '../utils/microsecondsTimeFormatter';
import { chartXAxisLabelFormatter } from '../../../helpers';
import { rawDataTooltipFormatter } from '../utils/rawDataTooltipFormatter';

export const createRawDataStackchartSeries = (
  key: string,
  dataArray: Array<any>,
  seriesCount: number,
  datapoint?: any
) => {
  const updatedOptions: Highcharts.Options = merge({}, defaultSettings(seriesCount, key), {
    xAxis: {
      labels: {
        formatter: microsecondsTimeFormatter,
        ...chartXAxisLabelFormatter
      }
    },
    yAxis: [
      {
        title: datapoint?.unit || 'value'
      }
    ],
    tooltip: {
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        return rawDataTooltipFormatter.call(this);
      },
      style: {
        color: 'white'
      },
      backgroundColor: 'rgba(77, 77, 77, 0.7)'
    },
    series: [
      {
        name: key,
        type: 'line',
        data: dataArray,
        /**
         * Add custom datapoint info so the tooltip formatter can pick them up.
         */
        custom: {
          datapoint: {
            unit: datapoint?.unit,
            sensor_type: datapoint?.sensor_type
          }
        }
      }
    ]
  }) as Highcharts.Options;

  return updatedOptions;
};
