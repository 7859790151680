/**
 * Line Chart View
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useGetNewAssetDashboardUrlParams from '../../../../features/device/new-asset-dashboard/custom-hooks/useGetNewAssetDashboardUrlParams';
import { ChartViewProps } from '../common/ChartViewProps';
import useCartesionChart from '../utils/useCartesianChart';
import { lineChartConfig as defaultLineChartSettings } from './lineChartSettings';
/**
 *
 */
const SLLineChartView: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet, widgetType, isDataInMilliseconds }: ChartViewProps, ref: any) => {
    const urlParams = useGetNewAssetDashboardUrlParams();
    const [hideSeries, setHideSeries] = useState<Set<string>>(new Set());

    const lineChartSettings = useCartesionChart(
      chartSettings,
      defaultLineChartSettings(),
      resultSet,
      widgetType,
      'line',
      hideSeries,
      setHideSeries,
      isDataInMilliseconds
    );

    const chartRef = useRef<HighchartsReact.RefObject | null>(null);

    useEffect(() => {
      if (urlParams?.trendChartHeight && chartRef?.current && chartRef?.current?.chart) {
        chartRef?.current?.chart?.reflow();
      }
    }, [urlParams?.trendChartHeight]);

    return (
      <HighchartsReact
        containerProps={{
          style: { height: urlParams?.trendChartHeight || 'auto' }
        }}
        highcharts={Highcharts}
        options={lineChartSettings}
        ref={(el) => {
          chartRef.current = el;
          if (typeof ref === 'function') ref(el);
          else if (ref) ref.current = el;
        }}
      />
    );
  }
);

export default SLLineChartView;
