import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group } from './types';

export enum DeviceActionTypes {
  CREATE_DEVICE = 'CREATE_DEVICE',
  CREATE_GROUP = 'CREATE_GROUP',
  EDIT_GROUP = 'EDIT_GROUP',
  VIEW_POWERTRAIN = 'VIEW_POWERTRAIN',
  CREATE_POWERTRAIN = 'CREATE_POWERTRAIN',
  EDIT_POWERTRAIN = 'EDIT_POWERTRAIN',
  COPY_POWERTRAIN = 'COPY_POWERTRAIN',
  MANAGE_DOCUMENTS = 'MANAGE_DOCUMENTS'
}

export type SelectedAction = `${DeviceActionTypes}` | null;

interface DeviceInitialState {
  newAssetDashboard: {
    rightDrawerAction: {
      isDrawerOpen: boolean;
      selectedAction: SelectedAction;
      /**
       * Pass the data to perform a particular action.
       * This is mainly created for the Edit-Group operation.
       */
      data?: Group;
    };
    dialogAction: {
      open: boolean;
      data?: Group;
    };
    isPowertrainDropdownOpen: boolean | undefined;
  };
  powertrainUserGroups: {
    isFilterApplied: boolean;
    groups: Group[];
  };
}

const initialState: DeviceInitialState = {
  newAssetDashboard: {
    rightDrawerAction: {
      isDrawerOpen: false,
      selectedAction: null,
      data: undefined
    },
    dialogAction: {
      open: false,
      data: undefined
    },
    isPowertrainDropdownOpen: undefined
  },
  powertrainUserGroups: {
    isFilterApplied: true,
    groups: []
  }
};

/**
 * This slice is used to manage the state of the new asset dashboard.
 */
export const assetDashboardSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    /**
     * Use this action to toggle the right drawer for creating device/powertrain/group form.
     * @param state - The current state of the store.
     * @param action - The action to be performed.
     */
    toggleRightDrawer: (
      state,
      action: PayloadAction<{
        isDrawerOpen: boolean;
        selectedAction: SelectedAction;
        data?: Group;
      }>
    ) => {
      state.newAssetDashboard.rightDrawerAction = action.payload;
    },
    /**
     * This action is created to help delete the group/powertrain in a dialog.
     * @param state - The current state of the store.
     * @param action - The action to be performed.
     */
    toggleDialog: (state, action: PayloadAction<{ open: boolean; data?: Group }>) => {
      state.newAssetDashboard.dialogAction = action.payload;
    },
    /**
     * This action is created to toggle the powertrain dropdown open state.
     * @param state - The current state of the store.
     * @param action - The action to be performed.
     */
    togglePowertrainDropdownOpenState: (state, action: PayloadAction<boolean | undefined>) => {
      state.newAssetDashboard.isPowertrainDropdownOpen = action.payload;
    },
    /**
     * Toggle filter for powertrain user groups.
     * @param state - The current state of the store.
     */
    togglePowertrainUserGroupsFilter: (state, action: PayloadAction<boolean>) => {
      state.powertrainUserGroups.isFilterApplied = action.payload;
    }
  }
});

export const {
  toggleRightDrawer,
  toggleDialog,
  togglePowertrainDropdownOpenState,
  togglePowertrainUserGroupsFilter
} = assetDashboardSlice.actions;

export default assetDashboardSlice.reducer;
