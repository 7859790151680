/**
 * VA summary Section Styles
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  icon1: {
    transform: 'rotate(90deg)'
  },
  accordionStyle: {
    marginTop: '20px',
    border: '1px solid #707070',
    padding: '0px',
    borderWidth: '1px',
    borderStyle: 'solid',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.34)'
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));
