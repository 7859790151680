/**
 * Delete Button With Tooltip
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { LightTooltip } from '../tooltip/Tooltip';
import { toSnakeCase } from '../../../i18n/translation.helper';

/**
 *
 */
interface DeleteButtonProps {
  tooltip?: string;
  onRemove: React.MouseEventHandler<HTMLButtonElement>;
}
/**
 *
 */
const DeleteButtonWithTooltip = React.memo(
  ({ tooltip = 'Delete', onRemove }: DeleteButtonProps) => {
    return (
      <LightTooltip title={tooltip} placement="bottom" translationId={toSnakeCase(tooltip)}>
        <IconButton onClick={onRemove} size="small">
          <ClearIcon color="primary" />
        </IconButton>
      </LightTooltip>
    );
  },
  (prevProp: DeleteButtonProps, nextProps: DeleteButtonProps) =>
    prevProp.tooltip === nextProps.tooltip
);
export default DeleteButtonWithTooltip;
