// Enum for tagging cache entries in RTK Query
export enum DOCUMENTS_TAG {
  POWERTRAIN_DOCUMENTS = 'POWERTRAIN_DOCUMENTS',
  COMPONENT_DOCUMENTS = 'COMPONENT_DOCUMENTS',
  TAGS = 'TAGS'
}

// Represents a document associated with a powertrain
export interface PowertrainDocument {
  powertrain_id: string;
  uploaded_by: string;
  uploaded_date: number; // timestamp
  document_tags: string[];
  components: string[];
  document_name: string;
  document_uuid: string;
  /**
   * View Document URL
   */
  presigned_url: string;
  /**
   * Download Document URL
   */
  download_url: string;
  metadata: Record<string, unknown>;
}

// Represents a document associated with a component
export interface ComponentDocument {
  component_id: string;
  uploaded_by: string;
  uploaded_date: number; // timestamp
  document_tags: string[];
  document_name: string;
  document_uuid: string;
  /**
   * View Document URL
   */
  presigned_url: string;
  /**
   * Download Document URL
   */
  download_url: string;
  metadata: Record<string, unknown>;
}

export interface PowertrainDocumentResponse {
  powertrain_documents: PowertrainDocument[];
  component_documents: ComponentDocument[];
}

// Represents a Tag for documents
export interface Tag {
  /**
   * Name of the tag in the keyand value is he UUID of the tag.
   */
  [key: string]: string;
}

// Arguments required to fetch powertrain documents
export interface FetchPowertrainDocumentsArgs {
  slug: string;
  powertrainId: number;
}

// Arguments required to fetch component documents
export interface FetchComponentDocumentsArgs {
  componentId: number;
}

interface UploadDocumentRequestPayload {
  content_type: string;
  file_name: string;
  tags: string[];
}

// Arguments for uploading a powertrain document
export interface PowertrainUploadDocumentArgs {
  slug: string;
  powertrainId: string;
  payload: UploadDocumentRequestPayload;
}

// Arguments for uploading a component document
export interface ComponentUploadDocumentArgs {
  componentId: string;
  payload: UploadDocumentRequestPayload;
}

// Response from an upload operation
export interface UploadPowertrainDocumentResponse {
  document: {
    name: string;
    upload_date: string; // timestamp in mS
    is_active: string; // "1" as a string, consider converting to boolean if needed
    powertrain_id: string;
    file_path: string;
    doc_metadata: string; // JSON string, consider using Record<string, any> if parsed
    uploaded_by: string;
    id: string;
    tags: string[]; // Array of strings
  };
  upload_url: string;
  message: string;
}

export interface UploadComponentDocumentResponse {
  document: {
    name: string;
    upload_date: string; // timestamp in mS
    is_active: string; // "1" as a string, consider converting to boolean if needed
    component_id: string;
    file_path: string;
    doc_metadata: string; // JSON string, consider using Record<string, any> if parsed
    uploaded_by: string;
    id: string;
    tags: string[]; // Array of strings
  };
  upload_url: string;
  message: string;
}

// Response from a delete operation
export interface DeleteDocumentResponse {
  status: string;
  message: string;
}

export interface DeletePowertrainDocumentArgs {
  slug: string;
  powertrainId: string;
  documentId: string;
}

export interface DeleteComponentDocumentArgs {
  componentId: string;
  documentId: string;
}
