import { APISERVICES } from '../../../framework';
import { featureApi } from '../../feature.api';
import {
  Alarm,
  AlarmNotes,
  ALARMS_VALIDATION_TAGS,
  FetchAlarmEventsResponse,
  FetchAlarmNotesArgs,
  FetchAlarmsArgs,
  FetchAlarmsCountArgs,
  FetchEventsFromAlarmArgs,
  FetchExportedAlarmsArgs,
  UpdateAlarmsStatusArgs,
  CreateAlarmNoteArgs,
  CreateAlarmNoteResponse,
  ALARM_TAG,
  FetchAlarmTimelineArgs,
  AlarmTimelines
} from './alarms.types';
import { RootState } from '../../../configureStore';
import { WEBSOCKET_TYPE } from '../../websocket';
import { alarmsMessageHandler } from '../../common/websocket/device/AlarmsMessageHandler';
import { onPerformAlarmsFileDownload, setShouldDownloadFile } from './alarmsFilters.slice';
import { WEBSOCKET_MODULE_OPTIONS } from '../../common/websocket/websocket.constants';
import { showAlarmsStateChangeToast } from './helper/alarms.helper';
import { generateTags } from '../../../framework/lib/rtk/RTK.utils';
import {
  CreateAlarmNoteQuery,
  fetchAlarmNotes,
  fetchAlarmTimelines
} from './alarm-utils/alarmQuery.utils';

export const UPDATE_ALARMS_STATUS_SHARED_RESULT = 'shared-update-alarms-status';

export const alarmsApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchAlarms: build.query<Alarm[], FetchAlarmsArgs>({
      query: ({
        slug,
        alarmType,
        isHistoricalAlarms,
        severity,
        start,
        end,
        device_ids,
        showProgressBar,
        alarm_ids
      }: FetchAlarmsArgs) => ({
        apiEndpoint: `orgs/${slug}/device_alarms?alarm_type=${alarmType}${
          severity ? `&severity=${severity.toString()}` : ''
        }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${
          isHistoricalAlarms ? `&states=FIXED,FALSE` : ''
        }${device_ids ? `&device_ids=${device_ids}` : ''}${
          alarm_ids ? `&alarm_ids=${alarm_ids}` : ''
        }`,
        params: null,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        actionKey: 'FETCH_ALARMS_LIST',
        showProgressBar
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, getState, dispatch }) {
        try {
          await cacheDataLoaded;
          const state = getState() as RootState;
          const ws = state.features.websocket.connections?.find(
            (connection) => WEBSOCKET_TYPE.DEVICE === connection.type
          );
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data);

            switch (data.module) {
              case WEBSOCKET_MODULE_OPTIONS.ALARMS.ALARMS_LIST:
                alarmsMessageHandler(dispatch, data, arg);
                break;
              case WEBSOCKET_MODULE_OPTIONS.ALARMS.HISTORICAL_ALARMS:
                dispatch(onPerformAlarmsFileDownload(data));
                break;
              default:
                break;
            }
          };

          if (ws?.connection && ws.connection.onmessage && (ws?.connection as WebSocket)) {
            ws?.connection.addEventListener('message', listener);
          }
        } catch {
          //
        }
      },
      transformResponse: (result) => result.results,
      providesTags: (result) => generateTags(result, ALARM_TAG.ALARMS)
    }),
    fetchAlarmsCount: build.query<any, FetchAlarmsCountArgs>({
      query: ({ slug, alarmType, count, showProgressBar }: FetchAlarmsCountArgs) => ({
        apiEndpoint: `orgs/${slug}/device_alarms?severity_count=${count}&alarm_type=${alarmType}`,
        params: null,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        actionKey: 'FETCH_ALARMS_COUNT',
        showProgressBar
      }),
      providesTags: [ALARMS_VALIDATION_TAGS.ALARM_COUNT],
      transformResponse: (result: any) => {
        return result;
      }
    }),
    fetchExportedAlarms: build.query({
      query: ({
        slug,
        start,
        end,
        setting,
        email,
        timezone,
        alarmType
      }: FetchExportedAlarmsArgs) => ({
        apiEndpoint: `orgs/${slug}/device_alarms/export?start=${start}&end=${end}&${
          setting === 'email' ? `email=${email}&` : ''
        }timezone=${timezone}&alarm_type=${alarmType}`,
        params: null,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        actionKey: 'FETCH_EXPORTED_ALARMS',
        onSuccess: (dispatch) => {
          dispatch(setShouldDownloadFile(true));
        }
      })
    }),
    updateAlarmsStatus: build.mutation<any, UpdateAlarmsStatusArgs>({
      query: ({
        slug,
        alarmStatus,
        ruleTriggerIds,
        isFixedFalse,
        summary,
        analystDiagnosis,
        analystRecommendation,
        analystNotes
      }) => ({
        apiEndpoint: `orgs/${slug}/device_alarms`,
        body: {
          alarm_status: alarmStatus,
          alarm_ids: ruleTriggerIds,
          ...(alarmStatus !== 'NEW' && isFixedFalse && { repair_summary: summary }),
          ...(alarmStatus === 'NEW' && {
            ...(analystDiagnosis && {
              analyst_diagnosis: analystDiagnosis
            }),
            ...(analystRecommendation && {
              analyst_recommendation: analystRecommendation
            }),
            ...(analystNotes && {
              analyst_notes: analystNotes
            })
          })
        },
        service: APISERVICES.DEVICE_API,
        method: 'PUT',
        actionKey: 'UPDATE_ALARMS_STATUS',
        onSuccess: (dispatch) => {
          showAlarmsStateChangeToast(dispatch, alarmStatus);
        }
      })
    }),
    fetchAlarmEvents: build.query<FetchAlarmEventsResponse, FetchEventsFromAlarmArgs>({
      query: ({ slug, device_id, dpids, start, end }: FetchEventsFromAlarmArgs) => ({
        apiEndpoint: `orgs/${slug}/devices/${device_id}/datapoints/narrowband_data`,
        params: {
          unit: 'inches/second',
          dpids,
          start,
          end
        },
        service: APISERVICES.VA_API,
        method: 'GET',
        actionKey: 'FETCH_ALARMS_EVENTS',
        showProgressBar: true
      }),
      transformResponse: (result: any) => {
        return result;
      }
    }),
    fetchAlarmNotes: build.query<AlarmNotes, FetchAlarmNotesArgs>({
      query: fetchAlarmNotes,
      providesTags: (result) => generateTags(result, ALARM_TAG.ALARM_NOTES)
    }),
    createAlarmNote: build.mutation<CreateAlarmNoteResponse, CreateAlarmNoteArgs>({
      async queryFn(arg, { dispatch }) {
        return CreateAlarmNoteQuery(arg, dispatch, ALARM_TAG.CREATE_ALARM_NOTE);
      },
      invalidatesTags: () => [ALARM_TAG.ALARM_NOTES]
    }),
    fetchAlarmTimelines: build.query<AlarmTimelines, FetchAlarmTimelineArgs>({
      query: fetchAlarmTimelines,
      providesTags: (result) => generateTags(result, ALARM_TAG.ALARM_TIMELINE)
    })
  })
});

export const {
  useFetchAlarmsQuery,
  useFetchAlarmsCountQuery,
  useFetchExportedAlarmsQuery,
  useLazyFetchExportedAlarmsQuery,
  useUpdateAlarmsStatusMutation,
  useFetchAlarmEventsQuery,
  useLazyFetchAlarmEventsQuery,
  useFetchAlarmNotesQuery,
  useLazyFetchAlarmNotesQuery,
  useCreateAlarmNoteMutation,
  useFetchAlarmTimelinesQuery,
  useLazyFetchAlarmTimelinesQuery
} = alarmsApi;
