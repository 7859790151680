import { useEffect, useState } from 'react';
import { Device } from '../../../../features/device/store/types';
import { LeakDetectionDateRangeType } from '../../../../features/reports/leak-detection/utils/LeakDetectionDateRange';
import { useLazyFetchAirborneDeviceData } from './store/deviceAirborneData.hooks';
import { airborneDatapoint, DeviceDataType, TimeData } from './leakDetectionTypes';
import { useLazyFetchDeviceDatapoints } from '../../../../features/device/store/device.hooks';
import { DataPoint } from '../../../../features/device/store/device.types';

// This function checks whether airborne microphone datapoint exists in the given list of datapoints for the given device id
const checkExistanceOfAirborneDatapoint = (datapoints: DataPoint[]) => {
  const target_dpid = 3000016;
  for (let i = 0; i < datapoints.length; i++) {
    const datapoint = datapoints[i];
    if (datapoint.dpid === target_dpid) {
      return true;
    }
  }
  return false;
};

// This function fetches the Airborne datapoint data for the devices having "Tank" in their name
export const useFetchAllDevicesData = (
  devices: Device[],
  selectedRange: LeakDetectionDateRangeType,
  slug: string
) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deviceIds, setDeviceIds] = useState<DeviceDataType>({});
  const {
    fetchLazyFetchAirborneData,
    isFetchingLazyDeviceAirborneData,
    isLazyDeviceAirborneDataFetchError
  } = useLazyFetchAirborneDeviceData();

  const { fetchLazyDeviceDatapoints, isFetchingLazyDeviceDatapoints, isLazyDeviceDatapointsError } =
    useLazyFetchDeviceDatapoints();

  const airborneLeakTriggerLimit = 128.0;

  // Get the airborne data for a particular device ID once we know it is present
  const getAirborneData = async (id: number) => {
    const { data, isSuccess, isError } = await fetchLazyFetchAirborneData(
      {
        id: id,
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate,
        slug
      },
      true
    );
    if (isSuccess) {
      if ('data' in data && airborneDatapoint in data.data) {
        const lazyDeviceAirborneData: TimeData[] = [];
        const airborneData = data.data[airborneDatapoint];
        airborneData.map((record) => {
          if (record[1] >= airborneLeakTriggerLimit) {
            lazyDeviceAirborneData.push({
              time: record[0],
              val: 1
            });
          }
        });
        setDeviceIds((prev) => ({ ...prev, [id]: lazyDeviceAirborneData }));
      }
    }
    if (isError) {
      setError(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        !isFetchingLazyDeviceAirborneData &&
        !isLazyDeviceAirborneDataFetchError &&
        !isFetchingLazyDeviceDatapoints &&
        !isLazyDeviceDatapointsError
      ) {
        const tankDevices = devices.filter((device) => device.name.includes('Tank'));

        tankDevices.map(async (device) => {
          // Check if the airborne datapoint is available on the current device
          const {
            data: datapoints,
            isSuccess: isDatapointSuccess,
            isError: isDatapointError
          } = await fetchLazyDeviceDatapoints(
            {
              slug,
              deviceId: device.id,
              vibrationAnalysis: 'false'
            },
            true
          );

          if (isDatapointError) {
            setError(true);
          } else if (isDatapointSuccess) {
            const existsAirborneData = checkExistanceOfAirborneDatapoint(datapoints);

            if (existsAirborneData) {
              await getAirborneData(device.id);
            }
          }
        });
        setIsLoading(false);
      } else if (isLazyDeviceAirborneDataFetchError || isLazyDeviceDatapointsError) {
        setError(true);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [devices, fetchLazyFetchAirborneData, fetchLazyDeviceDatapoints, selectedRange]);

  return {
    isAirborneDataError: isLazyDeviceAirborneDataFetchError || error,
    isAirborneDataLoading: isLoading,
    airborneData: deviceIds
  };
};
