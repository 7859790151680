import { FetchAnomalyListPayload } from '../anomaly-list/anomaly-list.state';

/**
 * Anomaly Details State
 * @author mahesh.kedari@shorelineiot.com
 */
export enum ANOMALY_DETAILS_STATE {
  INIT,
  IN_PROGRESS,
  DELETE_IN_PROGRESS,
  FETCHING,
  CREATED,
  UPDATED,
  DELETED,
  READY,
  ERROR
}

export interface AnomalyLevel {
  value: number;
  cause: string;
  recommendation: string;
  condition_id: number;
}

export interface VariableDefination {
  operator: string;
  operand: string;
  device_id: number;
  dp_id: number;
  time_unit?: string; // supported time units [‘day’, ‘hour’, ‘minute’]
  n?: number;
}

export interface AnomalyLevels {
  alert_low?: AnomalyLevel;
  alert_warn?: AnomalyLevel;
  alert_error?: AnomalyLevel;
  alert_fatal?: AnomalyLevel;
}

export type AnomalyConfigMode = 'ADD' | 'EDIT';

export type TypeValue = 'asset' | 'sensor';

export interface Anomaly {
  name: string;
  anomaly_type: TypeValue;
  expression: string;
  global_dpid?: number;
  skip_review: boolean;
  hide_anomaly?: string;
  id?: number;
  unit: string;
  variable_def: {
    [key: string]: VariableDefination;
  };
  anomaly: string;
  anomaly_levels: AnomalyLevels;
  /**
   * Determines if the anomaly is script-based or non-script based.
   * - If `true`, the anomaly is non-script based.
   * - If `false`, the anomaly is script-based.
   */
  eval_anomaly?: boolean;
  redirect_url?: string;
  redirect_url_type?: string;
  auto_close: boolean;
  skip_speed_check: boolean;
  auto_close_condition?: null | 'n in a row' | 'immediate';
  n_for_close_condition?: null | number;
}
/**
 *
 */
export interface AnomalyDetailsState {
  status: ANOMALY_DETAILS_STATE;
  anomaly?: Anomaly;
}

export type DerivedExpressionIds = number[];

export interface DeleteBulkAnomalyPayload extends FetchAnomalyListPayload {
  derived_expression_ids: DerivedExpressionIds;
}
