import { APISERVICES, showToast } from '../../../framework';
import { generateTags } from '../../../framework/lib/rtk/RTK.utils';
import { HTTP_METHOD } from '../../../framework/services/auth.service';
import { featureApi } from '../../feature.api';
import {
  DeleteDocumentResponse,
  DOCUMENTS_TAG,
  FetchPowertrainDocumentsArgs,
  FetchComponentDocumentsArgs,
  ComponentDocument,
  PowertrainUploadDocumentArgs,
  ComponentUploadDocumentArgs,
  UploadPowertrainDocumentResponse,
  Tag,
  DeletePowertrainDocumentArgs,
  DeleteComponentDocumentArgs,
  UploadComponentDocumentResponse,
  PowertrainDocumentResponse
} from './documentsTypes';

export const documentsApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    // ==============================
    // POWERTRAIN DOCUMENT ENDPOINTS
    // ==============================
    // Fetch powertrain documents
    fetchPowertrainDocuments: build.query<PowertrainDocumentResponse, FetchPowertrainDocumentsArgs>(
      {
        query: ({ slug, powertrainId }) => ({
          apiEndpoint: `orgs/${slug}/powertrain/${powertrainId}/documents`,
          params: null,
          service: APISERVICES.DEVICE_API,
          method: HTTP_METHOD.GET,
          actionKey: DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS
        }),
        providesTags: (result) => generateTags(result, DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS)
      }
    ),
    // Upload a new powertrain document
    uploadPowertrainDocument: build.mutation<
      UploadPowertrainDocumentResponse,
      PowertrainUploadDocumentArgs
    >({
      query: ({ slug, powertrainId, payload }) => ({
        apiEndpoint: `orgs/${slug}/powertrain/${powertrainId}/documents`,
        service: APISERVICES.DEVICE_API,
        method: HTTP_METHOD.POST,
        body: payload,
        actionKey: DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS
      }),
      invalidatesTags: [{ type: DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS, id: 'LIST' }]
    }),
    // Delete a powertrain document
    deletePowertrainDocument: build.mutation<DeleteDocumentResponse, DeletePowertrainDocumentArgs>({
      query: ({ slug, powertrainId, documentId }) => ({
        apiEndpoint: `orgs/${slug}/powertrain/${powertrainId}/documents/${documentId}`,
        service: APISERVICES.DEVICE_API,
        method: HTTP_METHOD.DELETE,
        actionKey: DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS,
        onSuccess(dispatch) {
          dispatch(showToast('Document deleted successfully!', 'success'));
        },
        onError(dispatch) {
          dispatch(showToast('Document could not be deleted', 'error'));
        }
      }),
      invalidatesTags: (_, __, arg) => [
        { type: DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS, id: arg.documentId },
        { type: DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS, id: 'LIST' }
      ]
    }),

    // ==============================
    // COMPONENT DOCUMENT ENDPOINTS
    // ==============================
    // Fetch component documents
    fetchComponentDocuments: build.query<ComponentDocument[], FetchComponentDocumentsArgs>({
      query: ({ componentId }) => ({
        // Assuming the component API path doesn't require "orgs/{slug}" prefix
        apiEndpoint: `component_compiler/${componentId}/documents`,
        params: null,
        service: APISERVICES.DEVICE_API,
        method: HTTP_METHOD.GET,
        actionKey: DOCUMENTS_TAG.COMPONENT_DOCUMENTS
      }),
      transformResponse: (response: { documents: ComponentDocument[] }) =>
        response?.documents || [],
      providesTags: (result) =>
        generateTags(result, DOCUMENTS_TAG.COMPONENT_DOCUMENTS, 'document_uuid')
    }),
    // Upload a new component document
    uploadComponentDocument: build.mutation<
      UploadComponentDocumentResponse,
      ComponentUploadDocumentArgs
    >({
      query: ({ componentId, payload }) => ({
        apiEndpoint: `component_compiler/${componentId}/documents`,
        service: APISERVICES.DEVICE_API,
        method: HTTP_METHOD.POST,
        body: payload,
        actionKey: DOCUMENTS_TAG.COMPONENT_DOCUMENTS
      }),
      invalidatesTags: [{ type: DOCUMENTS_TAG.COMPONENT_DOCUMENTS, id: 'LIST' }]
    }),
    // Delete a component document
    deleteComponentDocument: build.mutation<DeleteDocumentResponse, DeleteComponentDocumentArgs>({
      query: ({ componentId, documentId }) => ({
        apiEndpoint: `component_compiler/${componentId}/documents/${documentId}`,
        service: APISERVICES.DEVICE_API,
        method: HTTP_METHOD.DELETE,
        actionKey: DOCUMENTS_TAG.COMPONENT_DOCUMENTS,
        onSuccess(dispatch) {
          dispatch(showToast('Document deleted successfully!', 'success'));
        },
        onError(dispatch) {
          dispatch(showToast('Document could not be deleted', 'error'));
        }
      }),
      invalidatesTags: (_, __, arg) => [
        { type: DOCUMENTS_TAG.COMPONENT_DOCUMENTS, id: arg.documentId },
        { type: DOCUMENTS_TAG.COMPONENT_DOCUMENTS, id: 'LIST' }
      ]
    }),

    // ==============================
    // TAG MANAGEMENT ENDPOINTS
    // ==============================
    // Fetch all tags
    fetchTags: build.query<Tag[], void>({
      query: () => ({
        apiEndpoint: `tags`,
        service: APISERVICES.DEVICE_API,
        method: HTTP_METHOD.GET,
        actionKey: DOCUMENTS_TAG.TAGS
      }),
      transformResponse: (response: { tags: Tag[] }) => response.tags || [],
      providesTags: (result) => generateTags(result, DOCUMENTS_TAG.TAGS, 'id')
    }),
    // Create a new tag
    createTag: build.mutation<Tag, { name: string }>({
      query: ({ name }) => ({
        apiEndpoint: `tags`,
        service: APISERVICES.DEVICE_API,
        method: HTTP_METHOD.POST,
        body: { name },
        actionKey: DOCUMENTS_TAG.TAGS
      }),
      invalidatesTags: [{ type: DOCUMENTS_TAG.TAGS, id: 'LIST' }]
    })
  })
});

export const {
  useFetchPowertrainDocumentsQuery,
  useUploadPowertrainDocumentMutation,
  useDeletePowertrainDocumentMutation,
  useFetchComponentDocumentsQuery,
  useUploadComponentDocumentMutation,
  useDeleteComponentDocumentMutation,
  useFetchTagsQuery,
  useCreateTagMutation
} = documentsApi;
