import { createApi } from '@reduxjs/toolkit/query/react';
import { DEVICE_TAG } from './device/store/device.types';
import { ROLES_TAG_TYPES } from './org-settings/store/user-management/components/roles-list/role.types';
import { FIRMWARE_TAG_TYPES } from './firmware/store/firmware.types';
import { USER_TAG_TYPES } from './org-settings/store/user-management/components/user-list/user.types';
import { APITOKENS_TAG_TYPES } from './org-settings/store/apiTokens/apiToken.types';
import { ORGS_TAG_TYPES } from './organization/store/orgs/orgs.utils';
import { TOUR_TAG } from './home/tour/tour.types';
import { ALARMS_VALIDATION_TAGS, ALARM_TAG } from './alarms/store/alarms.types';
import { axiosBaseQuery } from '../framework/lib/rtk/axiosBaseQuery';
import { FEATURE_CONFIGURATIONS_TAG_TYPES } from './org-settings/store/feature-configurations/featureConfigurations.types';
import { ID_PROVIDER_TAG } from './auth/login/store/types/rtkTypes';
import { DOCUMENTS_TAG } from './documents/store/documentsTypes';
export const featureApi = createApi({
  reducerPath: 'feature',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [
    ROLES_TAG_TYPES.ROLES,
    APITOKENS_TAG_TYPES.APITOKEN,
    DEVICE_TAG.DEVICE_FIRMWARE_BY_ID,
    DEVICE_TAG.DEVICES,
    DEVICE_TAG.DEVICE,
    DEVICE_TAG.DEVICE_GROUPS,
    DEVICE_TAG.STANDALONE_DEVICES,
    DEVICE_TAG.DEVICE_TYPES,
    DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_COMPATIBLE_DEVICE_TYPE,
    DEVICE_TAG.DEVICE_PROFILES_DETAILS_BY_DEVICE_PROFILE_ID,
    DEVICE_TAG.DEVICE_SENSOR_TEMPLATES,
    DEVICE_TAG.CREATE_DEVICE,
    DEVICE_TAG.POWERTRAIN_COMPONENTS,
    DEVICE_TAG.POWERTRAIN_DETAIL,
    DEVICE_TAG.UPDATE_POWERTRAIN,
    DEVICE_TAG.CREATE_POWERTRAIN,
    DEVICE_TAG.DELETE_POWERTRAIN,
    DEVICE_TAG.CREATE_GROUP,
    DEVICE_TAG.EDIT_GROUP,
    DEVICE_TAG.DELETE_GROUP,
    DEVICE_TAG.MOVE_GROUP_TO_GROUP,
    DEVICE_TAG.MOVE_DEVICE_TO_GROUP,
    DEVICE_TAG.GENERIC_DASHBOARD,
    DEVICE_TAG.ASSET_WIDGET_DATA,
    DEVICE_TAG.SENSOR_WIDGET_DATA,
    DEVICE_TAG.IMAGE_WIDGET_DATA,
    DEVICE_TAG.UPDATE_SENSOR_WIDGET_DATA,
    DEVICE_TAG.DEVICE_PROFILES,
    DEVICE_TAG.DEVICE_EVENTS,
    DEVICE_TAG.DEVICE_SCHEDULE_CONFIG,
    DEVICE_TAG.DEVICE_PHOTOS,
    DEVICE_TAG.CATEGORIES,
    DEVICE_TAG.ACTIVITY_LOGS,
    DEVICE_TAG.ADDITIONAL_SENSORS,
    DEVICE_TAG.LATEST_POWERTRAIN_CONFIG,
    DEVICE_TAG.DEVICE_HISTORICAL_DATA_WITHOUT_SAMPLING,
    DEVICE_TAG.DEVICE_WIDGET_SETTING,
    DEVICE_TAG.DEVICE_DATAPOINTS,
    DEVICE_TAG.RAW_DATAPOINTS_BY_DEVICE_IDS,
    DEVICE_TAG.HISTORICAL_TREND_DATA,
    DEVICE_TAG.BATTERY_STATUS,
    DEVICE_TAG.ANOMALY_LIST,
    DEVICE_TAG.DIAL_WIDGET_DATA,
    DEVICE_TAG.DEVICE_MAILBOX,
    DEVICE_TAG.ALERTS,
    ALARMS_VALIDATION_TAGS.ALARM_COUNT,
    FIRMWARE_TAG_TYPES.FIRMWARE,
    USER_TAG_TYPES.USER,
    USER_TAG_TYPES.USER_GROUP,
    USER_TAG_TYPES.ORPHAN_GROUP,
    ORGS_TAG_TYPES.ORGS,
    ORGS_TAG_TYPES.SUBORGS,
    ORGS_TAG_TYPES.DATA_EXPORT,
    TOUR_TAG.TOUR,
    ALARM_TAG.ALARM_NOTES,
    ALARM_TAG.CREATE_ALARM_NOTE,
    ALARM_TAG.ALARM_TIMELINE,
    ALARM_TAG.ALARMS,
    FEATURE_CONFIGURATIONS_TAG_TYPES.ORG_FEATURE_LIST,
    DEVICE_TAG.AIRBORNE_DATA,
    ID_PROVIDER_TAG.ID_PROVIDER_EXISTS,
    'FETCH_SCRIPT_LIST',
    'FETCH_SCRIPT',
    DOCUMENTS_TAG.POWERTRAIN_DOCUMENTS,
    DOCUMENTS_TAG.COMPONENT_DOCUMENTS,
    DOCUMENTS_TAG.TAGS
  ]
});
