/**
 * Right Side Drawer
 * @author mahesh.kedari@shorelineiot.com
 */
import React, {
  ReactNode,
  useEffect,
  ReactElement,
  PropsWithChildren,
  useState,
  useImperativeHandle
} from 'react';
import clsx from 'clsx';
import { Button, Drawer, ButtonProps, Box, Typography } from '@mui/material';
import { useStyles } from './rightDrawer.styles';
import TranslateText from '../../../i18n/TranslateText';
import { toSnakeCase } from '../../../i18n/translation.helper';

interface Props extends ButtonProps {
  label: string | ReactNode;
  drawerTitle?: string;
  drawerOpen?: boolean;
  component?: React.ElementType;
  disableRipple?: boolean;
  onClose?: ((event: any, reason?: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
  onOpen?: () => void;
  handleClickOutsideListener?: React.ReactEventHandler<{}> | undefined;
  drawerHeaderContent?: React.ReactNode;
  translationId?: string;
  customPaper?: string;
}
/**
 *
 */
const SLRightDrawer = React.forwardRef(
  (
    {
      label,
      children,
      drawerTitle,
      drawerOpen,
      component: Component,
      disableRipple = true,
      onClose,
      onOpen,
      handleClickOutsideListener,
      disableFocusRipple = disableRipple,
      disableTouchRipple = disableRipple,
      drawerHeaderContent,
      customPaper,
      ...otherProps
    }: PropsWithChildren<Props>,
    ref: any
  ): ReactElement => {
    const [status, setStatus] = useState<boolean>(drawerOpen || false);
    const classes = useStyles();
    const toggleDrawer =
      (open: boolean) =>
      (
        event: React.KeyboardEvent | React.MouseEvent,
        reason?: 'backdropClick' | 'escapeKeyDown'
      ) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        if (!open && onClose) {
          onClose(event, reason);
        }
        if (open && onOpen) {
          onOpen();
        }
        setStatus(open);
      };

    useEffect(() => {
      if (drawerOpen !== undefined) {
        setStatus(drawerOpen);
      }
    }, [drawerOpen]);

    /**
     * Allow closing of drawer from parent component
     */
    useImperativeHandle(ref, () => ({
      handleCloseDrawer(event?: any) {
        if (onClose) {
          onClose(event);
        }
        setStatus(false);
      }
    }));
    /**
     *
     */
    const updatedComponent = Component ? (
      <Component onClick={toggleDrawer(true)} />
    ) : label ? (
      <Button
        onClick={toggleDrawer(true)}
        className={classes.root}
        disableRipple={disableRipple}
        disableFocusRipple={disableFocusRipple}
        disableTouchRipple={disableTouchRipple}
        {...otherProps}>
        {label}
      </Button>
    ) : null;
    return (
      <>
        {updatedComponent}
        <Drawer
          sx={{ /* zIndex: 1 */ position: 'absolute' }}
          // Override width if prop is passed
          onBackdropClick={handleClickOutsideListener}
          anchor="right"
          open={status}
          onClose={toggleDrawer(false)}
          classes={{
            paper: clsx(
              customPaper ? customPaper : classes.drawerPaper,
              !status && classes.drawerPaperClose
            )
          }}>
          {drawerTitle ? (
            <Box className={classes.drawerTitle}>
              <Typography variant="h3">
                {TranslateText(toSnakeCase(drawerTitle), drawerTitle)}
              </Typography>
              {drawerHeaderContent}
            </Box>
          ) : null}
          {children}
        </Drawer>
      </>
    );
  }
);

export default SLRightDrawer;
