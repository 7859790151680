/**
 * Anomaly Details Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../anomaly.action';
import * as ACTIONS from '../anomaly.actionTypes';
import {
  removeProgressFor,
  showProgressFor,
  SafeSaga,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  showToast,
  APISERVICES,
  GenericObject,
  httpPatch
} from '../../../../../framework';
import { listActions } from '../../../../device/device-list';
import { Anomaly, DeleteBulkAnomalyPayload, DerivedExpressionIds } from './anomaly-details.state';
import { SagaIterator } from 'redux-saga';
import { BulkUpdateAnomaly } from '../anomaly.types';

interface BulkUpdateAnomalyAction extends AnyAction {
  payload: {
    slug: string;
    data: BulkUpdateAnomaly;
  };
}

function fetchAnomalyDetails(slug: string, derived_dp_id: any) {
  return httpGet(`orgs/${slug}/derived_expressions/${derived_dp_id}`, null, APISERVICES.DEVICE_API);
}

function addAnomalyConfig(slug: string, payload: Anomaly) {
  return httpPost(`orgs/${slug}/derived_expressions`, payload, APISERVICES.DEVICE_API);
}

function updateAnomalyConfig(slug: string, payload: Anomaly) {
  return httpPut(`orgs/${slug}/derived_expressions/${payload.id}`, payload, APISERVICES.DEVICE_API);
}

function bulkUpdateAnomalyConfig(slug: string, payload: BulkUpdateAnomaly) {
  const { skip_review, derived_expression_ids } = payload;
  return httpPut(
    `orgs/${slug}/derived_expressions`,
    { skip_review, derived_expression_ids },
    APISERVICES.DEVICE_API
  );
}

function bulkUpdateAlertsConfig(slug: string, payload: BulkUpdateAnomaly) {
  const { skip_review, alert_ids } = payload;
  return httpPatch(`orgs/${slug}/alerts`, { skip_review, alert_ids }, APISERVICES.DEVICE_API);
}

function deleteAnomalyConfig(slug: string, derived_dp_id: string) {
  return httpDelete(
    `orgs/${slug}/derived_expressions/${derived_dp_id}`,
    null,
    APISERVICES.DEVICE_URL
  );
}

function deleteBulkAnomalyConfig(slug: string, derived_expression_ids: DerivedExpressionIds) {
  return httpDelete(
    `orgs/${slug}/derived_expressions`,
    { derived_expression_ids },
    APISERVICES.DEVICE_URL
  );
}

function getDatapointsForSelectedDevicesIDs(slug: string, deviceIdList: any) {
  return httpGet(
    `orgs/${slug}/devices/datapoints?device_ids=${deviceIdList}&vibration_analysis=false`,
    null,
    APISERVICES.DEVICE_API
  );
}

function getDeviceIDsfromResponse(response: any) {
  if (response?.variable_def) {
    const allDeviceIDs = Object.entries(response?.variable_def).map(
      ([, variableInfo]: [any, any]) => variableInfo?.device_id
    );
    return allDeviceIDs.join();
  }
  return '';
}

function* getAnomalyDetails(action: AnyAction) {
  const { derived_dpid } = action.payload;
  yield put(showProgressFor(ACTIONS.FETCH_ANOMALY_CONFIG));
  try {
    const result: Anomaly = yield call(fetchAnomalyDetails, action.payload.slug, derived_dpid);
    const deviceIDsString = getDeviceIDsfromResponse(result);
    // if (deviceIDsString !== "") {
    const DatapointList: GenericObject = yield call(
      getDatapointsForSelectedDevicesIDs,
      action.payload.slug,
      deviceIDsString
    );
    yield put(listActions.updateMultipleDeviceWithDatapoints(DatapointList?.datapoints));
    yield put(actions.fetchAnomalyDetailsSuccess(result));
  } catch (err) {
    yield put(showToast('Anomaly Configuration could not be fetched', 'error', true));
    yield put(actions.fetchAnomalyDetailsFailure(err));
  } finally {
    yield put(removeProgressFor(ACTIONS.FETCH_ANOMALY_CONFIG));
  }
}
/**
 *
 */
function* updateAnomalyDetails(action: AnyAction) {
  const anomalyDetails = action.payload.data;
  yield put(showProgressFor(ACTIONS.UPDATE_ANOMALY_CONFIG));
  try {
    const result: {} = yield call(updateAnomalyConfig, action.payload.slug, anomalyDetails);
    yield put(actions.updateAnomalySuccess(result));
    yield put(showToast('Anomaly Configuration updated successfully', 'success', false));
  } catch (err: any) {
    yield put(
      showToast(err?.message || 'Anomaly Configuration could not be updated', 'error', true)
    );
    yield put(actions.updateAnomalyFailure());
  } finally {
    yield put(removeProgressFor(ACTIONS.UPDATE_ANOMALY_CONFIG));
  }
}
/**
 *
 */

function* createAnomalyConfig(action: AnyAction) {
  const anomalyDetails = action.payload.data;
  yield put(showProgressFor(ACTIONS.CREATE_ANOMALY_CONFIG));
  try {
    const result: {} = yield call(addAnomalyConfig, action.payload.slug, anomalyDetails);
    yield put(actions.createAnomalyConfigSuccess(result));
    yield put(showToast('Anomaly Configuration created successfully', 'success', false));
  } catch (err: any) {
    yield put(
      showToast(err?.message || 'Anomaly Configuration could not be created', 'error', true)
    );
    yield put(actions.createAnomalyConfigFailure());
  } finally {
    yield put(removeProgressFor(ACTIONS.CREATE_ANOMALY_CONFIG));
  }
}
/**
 *
 */
function* deleteAnomalyDetails(action: AnyAction) {
  const { derived_dpid, slug, isSuperAdmin } = action?.payload || {};
  yield put(showProgressFor(ACTIONS.DELETE_ANOMALY_CONFIG));
  try {
    const result: Anomaly = yield call(deleteAnomalyConfig, slug, derived_dpid);
    yield put(actions.deleteAnomalyConfigSuccess(result));
    yield put(actions.fetchAnomalyList({ slug, isSuperAdmin }));
    yield put(showToast('Anomaly Configuration deleted successfully', 'success', false));
  } catch (err) {
    yield put(showToast('Anomaly Configuration could not be deleted', 'error', true));
    yield put(actions.fetchAnomalyDetailsFailure(err));
  } finally {
    yield put(removeProgressFor(ACTIONS.DELETE_ANOMALY_CONFIG));
  }
}

function* bulkUpdateAnomaly(action: BulkUpdateAnomalyAction) {
  const { slug, data } = action.payload;
  const isSkipActionSelected = data?.skip_review;
  try {
    if (data?.entityToUpdate === 'derived_expression_ids') {
      yield call(bulkUpdateAnomalyConfig, slug, data);
    } else if (data?.entityToUpdate === 'alert_ids') {
      yield call(bulkUpdateAlertsConfig, slug, data);
    }
    yield put(actions.bulkUpdateAnomalySuccess());
    yield put(
      showToast(
        `${isSkipActionSelected ? 'Anomaly review skipped successfully' : 'Anomaly review set successfully'}`,
        'success',
        false
      )
    );
  } catch (err) {
    yield put(actions.bulkUpdateAnomalyFailure());
    yield put(
      showToast(`${isSkipActionSelected ? 'Skip' : 'Set'} review of anomaly failed`, 'error', true)
    );
  }
}

function* deleteBulkAnomaly(action: { payload: DeleteBulkAnomalyPayload }) {
  const { slug, isSuperAdmin, derived_expression_ids } = action.payload;
  yield put(showProgressFor(ACTIONS.DELETE_BULK_ANOMALY_CONFIG));
  try {
    yield call(deleteBulkAnomalyConfig, slug, derived_expression_ids);
    yield put(actions.deleteBulkAnomalyConfigSuccess());
    yield put(actions.fetchAnomalyList({ slug, isSuperAdmin }));
    yield put(showToast('Anomaly Configuration bulk deleted successfully', 'success', false));
  } catch (err) {
    yield put(showToast('Anomaly Configuration could not be deleted', 'error', true));
    yield put(actions.fetchAnomalyDetailsFailure(err));
  } finally {
    yield put(removeProgressFor(ACTIONS.DELETE_BULK_ANOMALY_CONFIG));
  }
}
/**
 *
 */
export function* watchAnomalyDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      ACTIONS.FETCH_ANOMALY_CONFIG,
      SafeSaga(getAnomalyDetails, ACTIONS.FETCH_ANOMALY_CONFIG)
    ),
    takeEvery(
      ACTIONS.UPDATE_ANOMALY_CONFIG,
      SafeSaga(updateAnomalyDetails, ACTIONS.UPDATE_ANOMALY_CONFIG)
    ),
    takeEvery(
      ACTIONS.CREATE_ANOMALY_CONFIG,
      SafeSaga(createAnomalyConfig, ACTIONS.CREATE_ANOMALY_CONFIG)
    ),
    takeEvery(
      ACTIONS.DELETE_ANOMALY_CONFIG,
      SafeSaga(deleteAnomalyDetails, ACTIONS.DELETE_ANOMALY_CONFIG)
    ),
    takeEvery(
      ACTIONS.BULK_UPDATE_ANOMALY_CONFIG,
      SafeSaga(bulkUpdateAnomaly, ACTIONS.BULK_UPDATE_ANOMALY_CONFIG)
    ),
    takeEvery(
      ACTIONS.DELETE_BULK_ANOMALY_CONFIG,
      SafeSaga(deleteBulkAnomaly, ACTIONS.DELETE_BULK_ANOMALY_CONFIG)
    )
  ]);
}
