export enum DASHBOARD_ITEM_TYPE {
  RAW_DATA = 'raw_data',
  WATERFALL_CHART = 'waterfall_chart',
  BATTERY_STATUS = 'battery_status',
  MAP_CHART = 'map_chart',
  LEAK_DETECTION = 'leak_detection',
  DIAL = 'dial',
  PLANT_HEALTH = 'pm_plant_health',
  PLANT_HEALTH_TRENDS = 'pm_plant_health_trends',
  ASSET_SEVERITY_AGE = 'pm_asset_severity_age'
}
