/**
 * SLDropdown Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ComponentType, ReactElement } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuItemType } from '../../types';
import { LightTooltip } from '../tooltip/Tooltip';
import TranslateText from '../../../i18n/TranslateText';
import { toSnakeCase } from '../../../i18n/translation.helper';
/**
 *
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: '15px'
  },
  dropdownStyle: {
    width: '9vw',
    marginRight: '10px'
  },
  selectStyle: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.47)',
    borderWidth: 1,
    height: '30px',

    borderColor: theme.palette.grey[400],
    borderRadius: '28px',
    borderStyle: 'solid',

    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: '10px'
    },

    '@media(min-width: 1280px)': {
      borderWidth: 1,
      borderColor: theme.palette.grey[400],
      borderRadius: '28px',
      borderStyle: 'solid',
      paddingTop: '1px'
    },

    '@media(min-width: 1920px)': {
      borderWidth: 1,
      borderColor: theme.palette.grey[400],
      borderRadius: '28px',
      borderStyle: 'solid',
      paddingTop: '2px'
    },

    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
}));
/**
 *
 */
interface Props {
  list: Array<MenuItemType>;
  selectedValue: any;
  onMenuChange: (event: SelectChangeEvent<any>) => void;
  MenuItemView?: ComponentType<{ valueObject: any }>;
}
/**
 *
 */
export default function SLDropdown({
  list,
  selectedValue,
  onMenuChange,
  MenuItemView
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <FormControl
      className={classes.dropdownStyle}
      classes={{
        root: classes.root
      }}>
      <Select
        disableUnderline
        value={selectedValue}
        onChange={onMenuChange}
        className={`${classes.selectStyle} notranslate`}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}>
        {list.map((unit: any) =>
          unit.disabled && unit?.disableMsg ? (
            <LightTooltip key={unit.value} title={unit.disableMsg}>
              <span>
                <MenuItem key={unit.value} value={unit.value} disabled={unit.disabled}>
                  {MenuItemView ? (
                    <MenuItemView valueObject={unit} />
                  ) : (
                    TranslateText(toSnakeCase(unit.label), unit.label)
                  )}
                </MenuItem>
              </span>
            </LightTooltip>
          ) : (
            <MenuItem key={unit.value} value={unit.value} disabled={unit.disabled}>
              {MenuItemView ? (
                <MenuItemView valueObject={unit} />
              ) : (
                TranslateText(toSnakeCase(unit.label), unit.label)
              )}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
