/**
 * Cartesian Chart Common Settings
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { PropsWithChildren, ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import SLTextField from '../../../form/SLTextField';
import SLCheckbox from '../../../form/SLCheckbox';
import WidgetSettingAccordion from '../../common/WidgetAccordion';
import Options3dConfigs from './options-3d/Options3dConfigs';
import CrosshairConfigs from './CrosshairConfigs';
import { rules } from './cartesianChart.validation';
import { MULTIPLIER } from '../../utils/downSamplingData.utils';
import { RawDataCartersianPlotLines } from './plotlines/RawDataCartersianPlotLines';

interface Props {
  currentSettings: any;
  query?: any;
  handleUpdate: () => void;
}
export default function CartesianChartCommonSettings({
  currentSettings,
  query,
  handleUpdate,
  children
}: PropsWithChildren<Props>): ReactElement {
  return (
    <WidgetSettingAccordion title="Common">
      <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-start">
        <Grid item xs={6}>
          <SLTextField
            name="xAxisTitle"
            label="X-Axis Title"
            labelTranslationId="x_axis_title"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={6} justifyContent="center">
          <SLCheckbox
            name="marker"
            label="Marker On/Off"
            labelTranslationId="marker_on_off"
            onChange={handleUpdate}
            value={currentSettings?.marker}
            checked={currentSettings?.marker || false}
          />
        </Grid>
        <Grid item xs={6}>
          <SLCheckbox
            name="grid.disable"
            label="Grid Off"
            labelTranslationId="grid_off"
            onChange={handleUpdate}
            value={currentSettings?.grid?.disable}
            checked={currentSettings?.grid?.disable || false}
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name="grid.width"
            label="Grid width"
            labelTranslationId="grid_width"
            type="number"
            disabled={currentSettings?.grid?.disable}
            onBlur={handleUpdate}
          />
        </Grid>
        <CrosshairConfigs currentValue={currentSettings} handleUpdate={handleUpdate} />
        <Grid item xs={6}>
          <SLCheckbox
            name="singleYAxis"
            label="Single Y Axis"
            labelTranslationId="single_y_axis"
            onChange={handleUpdate}
            value={!!currentSettings?.singleYAxis}
            checked={!!currentSettings?.singleYAxis}
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name="singleYAxisTitle"
            label="Single Y Axis Title"
            labelTranslationId="single_y_axis_title"
            disabled={!currentSettings?.singleYAxis}
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <SLCheckbox
            name="manualScaleYAxis"
            label="Manual Scale Y Axis"
            labelTranslationId="manual_scale_y_axis"
            onChange={handleUpdate}
            value={!!currentSettings?.manualScaleYAxis}
            checked={!!currentSettings?.manualScaleYAxis}
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name="yAxisMin"
            label="Manual Scale Y Axis Min"
            labelTranslationId="y_axis_minimum"
            type="number"
            disabled={!currentSettings?.manualScaleYAxis}
            onBlur={handleUpdate}
            rules={rules.yAxisMin}
            triggerValidationMode="onChange"
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name="yAxisMax"
            label="Manual Scale Y Axis Max"
            labelTranslationId="y_axis_maximum"
            type="number"
            disabled={!currentSettings?.manualScaleYAxis}
            onBlur={handleUpdate}
            rules={rules.yAxisMax}
            triggerValidationMode="onChange"
          />
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name="threshold"
            label="Threshold (For Down Sampling)"
            labelTranslationId="threshold_for_down_sampling"
            type="number"
            value={currentSettings?.threshold || MULTIPLIER}
            onBlur={handleUpdate}
            rules={rules.threshold}
            triggerValidationMode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <RawDataCartersianPlotLines
            currentSettings={currentSettings}
            query={query}
            handleUpdate={handleUpdate}
          />
        </Grid>
      </Grid>
      {children /* Allow customization for specific chart type */}
      <Options3dConfigs currentValue={currentSettings} handleUpdate={handleUpdate} />
    </WidgetSettingAccordion>
  );
}
