/**
 * Password Form
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { MouseEventHandler, ReactElement } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useStyles } from '../../shared/authForm.styles';
import {
  closeAllToasts,
  showProgressFor,
  SLForm,
  SLPasswordField,
  SLSubmit
} from '../../../../framework';
import ForgotPassword from './ForgotPasswordLink';
import { rules } from './loginForm.validation';
import { loginActions } from '../store/actions';
import { useUser } from '../store/reducers/login.selectorHooks';

const LOGIN_ACTION_PROGRESS_ID = 'LOGIN_ACTION_PROGRESS_ID';
/**
 *
 */
interface Props {
  handleBackClick: MouseEventHandler<any>;
}
/**
 *
 * @param param0
 * @returns
 */
export default function PasswordForm({ handleBackClick }: Props): ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useUser() as { email: string };
  const onSubmit = (data: any) => {
    dispatch(showProgressFor(LOGIN_ACTION_PROGRESS_ID));
    if (data.rememberme) {
      localStorage.setItem('savedEmail', data.email.trim());
    } else {
      localStorage.removeItem('savedEmail');
    }

    const payload = {
      ...data,
      email: user?.email.trim()
    };
    dispatch(loginActions.login(payload));
    dispatch(closeAllToasts());
  };

  if (user.email) {
    return (
      <SLForm defaultValues={user} onSubmit={onSubmit}>
        <Box display="flex" flexDirection="row" width="auto" maxWidth="500px" alignItems="center">
          <IconButton onClick={handleBackClick} size="large" id="login-form-back-button">
            <ArrowBack />
          </IconButton>
          {user.email}
        </Box>
        <Box display="flex" flexDirection="column" width="auto" maxWidth="500px">
          <SLPasswordField
            name="password"
            label="Password"
            translationId="password"
            rules={rules.password}
            autoFocus={true}
          />
        </Box>
        <Box display="flex" justifyContent="right" mt={3}>
          <SLSubmit
            size="small"
            className={classes.submitButton}
            dataTestId="password-form-login-button"
            translationId="log_in">
            Login
          </SLSubmit>
        </Box>
        <Box justifyContent="left" textAlign="left" alignContent="center" mt={1}>
          <ForgotPassword email={user.email} />
        </Box>
      </SLForm>
    );
  }
  return <></>;
}
