/**
 * Breadcrumb Selector hook
 * @author prerna.more@shorelineiot.com
 */
import { useSelector } from 'react-redux';
import { RootState } from '../../../configureStore';

const useBreadcrumbState = () =>
  useSelector((state: RootState) => state.framework.breadcrumb.breadcrumbs);

const useShouldMoveBreadcrumbToRightState = () =>
  useSelector(
    (state: RootState) => state.framework.breadcrumb.shouldMoveBreadcrumbToRight.shouldMoveToRight
  );

const useShouldMoveBreadcrumbToRightPixel = () =>
  useSelector((state: RootState) => state.framework.breadcrumb.shouldMoveBreadcrumbToRight.pixel);
const useCollapseBreadcrumbConfig = () =>
  useSelector((state: RootState) => state.framework.breadcrumb.collapseBreadcrumbConfig);

export {
  useBreadcrumbState,
  useShouldMoveBreadcrumbToRightState,
  useShouldMoveBreadcrumbToRightPixel,
  useCollapseBreadcrumbConfig
};
