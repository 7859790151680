/**
 * SL Table Utils
 * @author aditya.bhadange@shorelineiot.com
 */

import { TableCellProps, useTheme } from '@mui/material';
import { MRT_Column, MRT_Row, MRT_RowData } from 'material-react-table';
import { Colors } from '../../constants';
import { ColumnFilter, SLTableColumn } from './slTable.types';
import { TreeDataType } from '../../types/MultiSelectDropdownTypes';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

type customColumnDef = MRT_RowData & {
  disableCellClick?: boolean;
};

/**
 * This is added for the Narrowband table only.
 * @param rowData
 * @returns boolean
 */
const isSourceDevice = (rowData: any) => {
  if (rowData?.source === 'device') {
    return true;
  }
  return false;
};

export const DisableCellClickFunc = (
  onRowClick: ((rowData: any) => void | undefined) | undefined,
  column: MRT_Column<any>,
  row: MRT_Row<any>,
  paperColor: string | undefined,
  textAlign: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
): TableCellProps => {
  const theme = useTheme();
  const customColumnDefData: customColumnDef = { ...column.columnDef } as any;
  const isActionsColumn: boolean = column?.id === 'mrt-row-actions';
  const { disableCellClick } = customColumnDefData;
  if (!onRowClick || disableCellClick || isActionsColumn) {
    return {
      onClick: () => {
        return null;
      },
      align: textAlign || 'left',
      sx: {
        cursor: isActionsColumn || disableCellClick ? 'default' : 'auto',
        backgroundColor: paperColor || theme.palette.background.paper,
        ...(isSourceDevice(row?.original) && { color: Colors.secondary })
      }
    };
  }
  return {
    onClick: () => {
      if (disableCellClick || isActionsColumn) {
        return null;
      }
      if (onRowClick) {
        onRowClick(row?.original);
      }
      return row.getToggleSelectedHandler();
    },
    align: textAlign || 'left',
    sx: {
      cursor: isActionsColumn || disableCellClick ? 'default' : 'pointer',
      backgroundColor: paperColor || theme.palette.background.paper,
      ...(isSourceDevice(row?.original) && { color: Colors.secondary })
    }
  };
};

/**
 * Retrieves titles from an array of options based on matching values.
 *
 * @param {string[]} labelArray - An array of values to match.
 * @param {{ value: string; title: string }[]} optionArray - An array of objects with 'title' and 'value' properties.
 * @returns {string[]} An array of titles corresponding to the values present in labelArray.
 */
export function getTitlesForValues(labels: string[], options: { value: string; title: string }[]) {
  return options.filter((option) => labels.includes(option.value)).map((option) => option.title);
}

/**
 * Generates column filters value based on column configurations.
 * @returns An array of ColumnFilter objects representing the filters.
 */
export const getColumnFiltersDefaultValuesInTitles = (
  columns: SLTableColumn<any>[]
): ColumnFilter[] => {
  const filters: ColumnFilter[] = [];
  columns.forEach((columnObj) => {
    const columnId = columnObj.accessorKey as string;
    if (columnObj.defaultFilterValue) {
      if (columnObj?.filterVariant === 'multi-select-with-search') {
        filters.push({
          id: columnId,
          value: findTitlesForValues(
            columnObj.defaultFilterValue as string[],
            columnObj?.multiSelectWithSearchOptions ? columnObj?.multiSelectWithSearchOptions : []
          )
        });
      } else {
        filters.push({ id: columnId, value: columnObj.defaultFilterValue as string[] });
      }
    }
  });
  return filters;
};

/**
 * Return column filters default value based on column configurations.
 * @returns An array of ColumnFilter objects representing the filters.
 */
export const getColumnFiltersDefaultValues = (columns: SLTableColumn<any>[]): ColumnFilter[] => {
  return columns.reduce((filters: ColumnFilter[], columnObj) => {
    const columnId = columnObj.accessorKey as string;
    if (columnObj.defaultFilterValue !== undefined && columnObj.defaultFilterValue !== null) {
      filters.push({ id: columnId, value: columnObj.defaultFilterValue as string[] });
    }
    return filters;
  }, []);
};

/**
 * Recursively finds titles corresponding to given values in a tree-like structure.
 * @param values Array of values to search for.
 * @param options Array of tree nodes to search within.
 * @returns Array of titles corresponding to the given values.
 */
export function findTitlesForValues(values: string[], options: TreeDataType[]): string[] {
  const titles = [];
  for (const option of options) {
    // If the current option's value is included in the provided values array,
    // push its title to the result array.
    if (values.includes(option.value)) {
      titles.push(option.title);
    }
    // If the current option has children, recursively call the function
    // on those children and concatenate the results with the current titles array.
    if (option.children) {
      titles.push(...findTitlesForValues(values, option.children));
    }
  }
  return titles;
}

/**
 * Recursively finds values corresponding to given titles in a tree-like structure.
 * @param titles Array of titles to search for.
 * @param options Array of tree nodes to search within.
 * @returns Array of values corresponding to the given titles.
 */
export function findValuesForTitles(titles: string[], options: TreeDataType[]): string[] {
  const values = [];
  for (const option of options) {
    // If the current option's title is included in the provided titles array,
    // push its value to the result array.
    if (titles.includes(option.title)) {
      values.push(option.value);
    }
    // If the current option has children, recursively call the function
    // on those children and concatenate the results with the current values array.
    if (option.children) {
      values.push(...findValuesForTitles(titles, option.children));
    }
  }
  return values;
}

/**
 * Checks if all columns with 'multi-select-with-search' filter variant
 * have options with non-zero length.
 * @param columnArray Array of table columns to check.
 * @returns True if all relevant columns have options with non-zero length, false otherwise.
 */
export const allMultiSelectOptionsHaveLength = (columnArray: SLTableColumn<any>[]) => {
  if (!columnArray?.length) return false;

  // Filter relevant columns with 'multi-select-with-search' filter variant
  const relevantColumns = columnArray.filter(
    (columnObj) => columnObj?.filterVariant === 'multi-select-with-search'
  );

  // Check if every relevant column has options with non-zero length
  if (relevantColumns.length) {
    return relevantColumns.every(
      (columnObj) =>
        Array.isArray(columnObj?.multiSelectWithSearchOptions) &&
        columnObj.multiSelectWithSearchOptions.length > 0
    );
  } else {
    return true;
  }
};

/**
 * Extracts the accessor keys of columns that have a filter variant of 'multi-select-with-search'.
 * @param columns Array of SLTableColumn objects to be checked.
 * @returns Array of accessor keys (as strings) for columns with a 'multi-select-with-search' filter variant.
 */
export const getMultiSelectColumnName = (columns: SLTableColumn<any>[]): string[] => {
  return columns.reduce((filters: string[], columnObj) => {
    const columnId = columnObj.accessorKey as string;
    if (columnObj.filterVariant === 'multi-select-with-search') {
      filters.push(columnId);
    }
    return filters;
  }, []);
};
/**
 * Returns MRT localization based on selected language
 * @param language selected language
 * @returns MRT Localization
 */
export const getSelectedLanguageLocalization = (language: string) => {
  switch (language) {
    case 'es':
      return MRT_Localization_ES;
    default:
      return MRT_Localization_EN;
  }
};

/**
 * Checks if any of the given columns have filter values.
 * @param columns Array of SLTableColumn objects to be checked.
 * @returns true if any of the columns have filter values, false otherwise.
 */
export const hasFilterValues = (columns: SLTableColumn<any>[]) =>
  columns.some((column) => Object.prototype.hasOwnProperty.call(column, 'filterValues'));

/**
 * Retrieves column filters based on their configurations and filter values.
 *
 * Iterates through the given array of columns and constructs an array of
 * `ColumnFilter` objects. For columns with a 'multi-select-with-search' filter
 * variant, it translates filter values into their corresponding titles.
 * Otherwise, it uses filter values.
 *
 * @param {SLTableColumn<any>[]} columns - An array of column objects used to
 * determine filter configurations.
 * @returns {ColumnFilter[]} An array of `ColumnFilter` objects representing
 * the filters configured for the columns.
 */

export const getColumnFiltersValues = (columns: SLTableColumn<any>[]): ColumnFilter[] => {
  const filters: ColumnFilter[] = [];
  columns.forEach((columnObj) => {
    const columnId = columnObj.accessorKey as string;
    if (columnObj.filterValues) {
      if (columnObj?.filterVariant === 'multi-select-with-search') {
        filters.push({
          id: columnId,
          value: findTitlesForValues(
            columnObj.filterValues as string[],
            columnObj?.multiSelectWithSearchOptions ? columnObj?.multiSelectWithSearchOptions : []
          )
        });
      }
    }
  });
  return filters;
};
