import { Box, Grid2 } from '@mui/material';
import { SLSelect, SLTextField } from '../../../../form';
import { useRawDatapoints } from '../../../../../../features/dashboard/utils/useRawDatapoints';
import { getDatapointSeriesWithDeviceName } from '../../../common/getDatapointSeries';
import { useSlugContext } from '../../../../../lib';
import { useFetchDevices } from '../../../../../../features/device';
import { dashStyles } from '../cartesianChart.utils';
import SLColorPicker from '../../../../color-picker/SLColorPicker';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SLDeleteButtonWithTooltip, SLDivider } from '../../../..';
import TranslateText from '../../../../../../i18n/TranslateText';
import SLAddButtonWithTooltip from '../../../../button/AddButtonWithTooltip';
import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

interface Props {
  currentSettings: any;
  query?: any;
  handleUpdate: () => void;
}

const useStyles = makeStyles(() => ({
  plotLineContainer: {
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
    margin: '0.5rem 0'
  }
}));

export const RawDataCartersianPlotLines = ({ currentSettings, query, handleUpdate }: Props) => {
  const classes = useStyles();
  const { slug } = useSlugContext();
  const { datapointsList } = useRawDatapoints();
  const { control } = useFormContext();

  const { devices } = useFetchDevices({
    slug
  });

  const datapointSeries = useMemo(
    () => getDatapointSeriesWithDeviceName(query?.data?.devices, datapointsList, devices),
    [query?.data?.devices, datapointsList, devices]
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'plotLines'
  });

  const handleRemove = (index: number) => {
    remove(index);
    handleUpdate();
  };

  const handleClick = () => {
    append({
      value: '',
      selectSensorsAxis: '',
      label: '',
      dashStyle: 'Solid',
      color: { hex: '#ff0000' },
      thickness: 2
    });

    handleUpdate();
  };
  return (
    <>
      <SLDivider textAlign="center">{TranslateText('add_plotline', 'Add Plotline')}</SLDivider>
      {fields &&
        fields.map((item, index) => (
          <Box key={index} className={classes.plotLineContainer}>
            <Grid2 container display={'flex'} alignItems={'flex-end'} spacing={1.3}>
              <Grid2 size={4}>
                <SLTextField
                  name={`plotLines.${index}.value`}
                  label={'Value'}
                  labelTranslationId="value"
                  type={'number'}
                  onBlur={handleUpdate}
                  value={currentSettings?.plotLines?.[index]?.value}
                />
              </Grid2>
              <Grid2 size={4}>
                <SLTextField
                  name={`plotLines.${index}.label`}
                  label={'Label'}
                  labelTranslationId="label"
                  onBlur={handleUpdate}
                  value={currentSettings?.plotLines?.[index]?.label}
                />
              </Grid2>
              <Grid2 size={4}>
                <SLSelect
                  name={`plotLines.${index}.dashStyle`}
                  label="Style"
                  translationId="style"
                  options={dashStyles}
                  onSelectChange={handleUpdate}
                  value={currentSettings?.plotLines?.[index]?.dashStyle}
                />
              </Grid2>

              <Grid2 size={6}>
                <SLSelect
                  name={`plotLines.${index}.selectSensorsAxis`}
                  label="Select Sensor"
                  translationId="select_sensor"
                  options={datapointSeries}
                  onSelectChange={handleUpdate}
                  value={currentSettings?.plotLines?.[index]?.selectSensorsAxis}
                />
              </Grid2>
              <Grid2 size={3}>
                <SLTextField
                  name={`plotLines.${index}.thickness`}
                  label={'Thickness'}
                  labelTranslationId="thickness"
                  type={'number'}
                  onBlur={handleUpdate}
                  value={currentSettings?.plotLines?.[index]?.thickness}
                />
              </Grid2>
              <Grid2 size={3}>
                <SLColorPicker
                  name={`plotLines.${index}.color`}
                  labelTranslationId="color"
                  handleColorChange={handleUpdate}
                  value={currentSettings?.plotLines?.[index]?.color || { hex: '#ff0000' }}
                />
              </Grid2>
            </Grid2>
            <SLDeleteButtonWithTooltip onRemove={() => handleRemove(index)} />
          </Box>
        ))}
      <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
        <SLAddButtonWithTooltip onAdd={handleClick} />
      </Box>
    </>
  );
};
