import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimReportState } from './simReport.types';
const initialState: SimReportState = {
  filters: {
    sim_config: [],
    cellular_stats: [],
    battery_status: [],
    last_connected: []
  }
};

export const simReport = createSlice({
  name: 'simReport',
  initialState,
  reducers: {
    handleSimConfigFilterChange(state: SimReportState, action: PayloadAction<string[]>) {
      state.filters.sim_config = action.payload;
    },
    handleCellularStatusFilterChange(state: SimReportState, action: PayloadAction<string[]>) {
      state.filters.cellular_stats = action.payload;
    },
    handleBatteryStatusFilterChange(state: SimReportState, action: PayloadAction<string[]>) {
      state.filters.battery_status = action.payload;
    },
    handleLastConnectedFilterChange(state: SimReportState, action: PayloadAction<string[]>) {
      state.filters.last_connected = action.payload;
    }
  }
});

export const {
  handleSimConfigFilterChange,
  handleCellularStatusFilterChange,
  handleBatteryStatusFilterChange,
  handleLastConnectedFilterChange
} = simReport.actions;

export default simReport.reducer;
